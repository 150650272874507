<template>
  <v-container>
    <v-btn
      v-for="report in reports"
      @click="openReport(report)"
      rounded="lg"
      class="ma-1"
      color="blue-darken-1"
      :variant="report.id == chosenReport.id ? 'elevated' : 'outlined'"
      >{{ report.key_name_ger }}</v-btn
    >
    <v-row>
      <v-col cols="3">
        <v-text-field
          type="date"
          v-model="today"
          class="mx-8 mt-4"
          label="Von"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          type="date"
          v-model="end"
          class="mt-4"
          label="Bis"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="mt-6">
        <v-btn @click="openReport(report)" :disabled="!report.id"
          >Neu Laden</v-btn
        >
      </v-col>
      <v-col cols="3" class="mt-4">
        <v-checkbox
          v-model="inactive"
          label="Inaktive Mitarbeiter zeigen"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>

  <v-data-table class="ma-4" :items="table.items" :headers="table.headers">
    <template v-slot:item.name="{ item }">
      <RouterLink :to="'/Mitarbeiter/' + item.id">{{ item.name }}</RouterLink>
      <v-chip size="x-small" class="ma-2">{{ item.status }}</v-chip>
    </template>
    <template v-slot:item.hire_date="{ item }">
      {{ transformDate(item.hire_date, item.age, "hire_date") }}
    </template>
    <template v-slot:item.date="{ item }">
      {{ transformDate(item.date, item.age) }}
    </template>
  </v-data-table>
</template>

<script setup>
import {
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  setYear,
  endOfYear,
} from "date-fns";
</script>

<script>
export default {
  data() {
    return {
      chosenReport: {},
      reports: [],
      today: null,
      end: null,
      inactive: null,
      table: {
        items: [],
        headers: [],
      },
      report: {},
    };
  },
  methods: {
    async getAllDateValues() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_data_keys",
        where: [{ type: "eq", key: "type", value: "date" }],
      });
      response.data.push({
        id: 29,
        key_name_ger: "Ende Probezeit",
      });
      this.reports = response.data;
    },
    async openReport(report) {
      this.chosenReport = report;
      this.table.items = [];
      this.report = report;
      let today = parseISO(this.today);
      let end = this.end ? parseISO(this.end) : null;

      const queryObject = {
        table: "employees",
        select: "*,employee_data_matching!inner(*)",
        where: [
          {
            type: "in",
            key: "employee_data_matching.key_id",
            value: [23, report.id],
          },
        ],
      };
      if (!this.inactive) {
        queryObject.where.push({
          type: "eq",
          key: "status",
          value: "Aktiv",
        });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );

      response.data.forEach((item) => {
        const hireDateKey = item.employee_data_matching.find(
          (item) => item.key_id == 23,
        );
        item.hire_date = hireDateKey ? parseISO(hireDateKey.value_date) : null;

        const matching = item.employee_data_matching.find(
          (realKey) => realKey.key_id == report.id,
        );
        if (matching && matching.value_date) {
          let dateValue = parseISO(matching.value_date);
          if (report.id == 16) {
            const currentYear = new Date().getFullYear();
            if (!end) {
              end = endOfYear(today);
            }
            // Set year to current year
            dateValue = setYear(dateValue, currentYear);
            // If the birthday has already passed this year, set to next year
            if (isBefore(dateValue, today)) {
              dateValue = setYear(dateValue, currentYear + 1);
            }
            item.age = this.calculateAge(
              parseISO(matching.value_date),
              new Date(),
            );
          }
          item.date = dateValue;
        }
      });

      if (report.id == 29) {
        response.data = await this.getProbationTimes();
      }

      response.data.sort((a, b) => a.date - b.date);
      response.data = response.data.filter((item) => {
        if (
          item.date &&
          (isAfter(item.date, today) || isEqual(item.date, today)) &&
          (!end || isBefore(item.date, end))
        ) {
          return true;
        }
        return false;
      });
      this.table.items = response.data;

      this.table.headers = [
        { key: "name", title: "Name", value: "name" },
        { key: "date", title: report.key_name_ger, value: "date" },
      ];
      if (report.id != 23) {
        this.table.headers.push({
          key: "hire_date",
          title: "Anstellungsdatum",
          value: "hire_date",
        });
      }
      if (report.id == 29) {
        this.table.headers.push({
          key: "probation_months",
          title: "Probezeitdauer",
          value: "probation_months",
        });
      }
    },
    transformDate(date, age, hire_date) {
      var newDate = date.getDate() + "." + (date.getMonth() + 1) + ".";
      if (this.report.id != 16 || hire_date) {
        newDate = newDate + date.getFullYear();
      } else {
        newDate = newDate + " (" + age + " Jahre)";
      }
      return newDate;
    },

    async getProbationTimes() {
      var relevantData = {
        data: [],
      };
      var queryObject = {
        table: "employees",
        select: "*,employee_data_matching(*)",
        where: [],
      };

      if (!this.inactive) {
        queryObject.where.push({
          type: "eq",
          key: "status",
          value: "Aktiv",
        });
      }
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        var probationDates = {};
        item.employee_data_matching.forEach((matching) => {
          if (matching.key_id == 29) {
            probationDates.probationMonths = matching.value_number;
            item.probation_months = matching.value_number;
          }
          if (matching.key_id == 23) {
            probationDates.hire_date = matching.value_date;
            item.hire_date = new Date(matching.value_date);
          }
        });
        if (probationDates.probationMonths && probationDates.hire_date) {
          item.date = this.calcProbationTime(probationDates);
          relevantData.data.push(item);
        }
      });
      return relevantData.data;
    },
    calcProbationTime(probationDates) {
      var checkDate = new Date(probationDates.hire_date);
      var months = probationDates.probationMonths;
      if (checkDate && months) {
        checkDate.setMonth(checkDate.getMonth() + parseInt(months, 10));
        checkDate.setDate(checkDate.getDate() - 1);
      }
      return checkDate;
    },
    calculateAge(birthDate, currentDate) {
      var age = currentDate.getFullYear() - birthDate.getFullYear();
      var monthDiff = currentDate.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    },
  },
  async created() {
    var today = new Date();
    this.today = today.toISOString().slice(0, 10);
    this.getAllDateValues();
  },
};
</script>
