<template>
  <v-row class="ma-2">
    <v-col cols="4" v-if="roleRights.can_change_user">
      <v-autocomplete
        clearable
        variant="outlined"
        @update:modelValue="getSelections()"
        :items="users"
        label="Anmelden als"
        item-title="full_name"
        return-object
        v-model="chosenUser"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" align="right" class="my-n2" v-if="employeeId">
      <v-btn color="red" @click="$store.state.employeeView.dialog = false">
        <v-icon> mdi-close </v-icon></v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-progress-linear
        :model-value="progress"
        class="my-3"
        height="15"
        color="blue-darken-4"
        v-if="loading"
      ></v-progress-linear>
    </v-col>
    <v-col cols="12" v-if="!loading && !showProfile">
      Profil ist nicht einsehbar
    </v-col>
    <v-col cols="12" sm="4">
      <TaskManagement
        entity="employee_id"
        :entityId="employeeInfo.id"
        :entityInfo="employeeInfo"
        v-if="employeeInfo.id && showProfile"
      ></TaskManagement>
      <v-row v-if="employeeInfo.id && showProfile">
        <v-col align="center" cols="12">
          <v-chip
            size="large"
            variant="elevated"
            :color="employeeInfo.status == 'Aktiv' ? 'success' : 'grey'"
            >{{ employeeInfo.status }}</v-chip
          >
        </v-col>
        <v-col align="left" class="text-h6">{{ employeeInfo.name }} </v-col>

        <v-col align="right">
          <v-icon
            size="x-large"
            :icon="employeeInfo.is_visible ? 'mdi-eye' : 'mdi-eye-off'"
            @click="changeVisibility(employeeInfo)"
            v-if="role.can_hide_employees"
          ></v-icon>
          <v-icon
            size="x-large"
            icon="mdi-account-off"
            @click="endContract.dialog = true"
            v-if="role.can_end_contract"
          ></v-icon>
        </v-col>
      </v-row>
      <v-card v-if="contractEnded" variant="outlined" color="red">
        <v-card-title
          >Arbeitsverhältnis wurde auf beendet gestellt</v-card-title
        >
        <v-card-text>
          <v-hover v-for="section in profileStructure">
            <template v-slot:default="{ isHovering, props }">
              <v-card
                v-bind="props"
                :elevation="isHovering ? '10' : '2'"
                rounded="xl"
                v-if="section.id == 4"
              >
                <v-row class="px-6 pt-12">
                  <v-col
                    v-for="field in section.employee_data_keys"
                    cols="12"
                    class="mt-n6"
                  >
                    <template
                      v-if="
                        field.id == 65 ||
                        field.id == 66 ||
                        field.id == 59 ||
                        field.id == 60
                      "
                    >
                      <v-text-field
                        variant="outlined"
                        density="compact"
                        class="shrink"
                        type="date"
                        v-if="field.type == 'date'"
                        :label="field.key_name_ger"
                        v-model="field.employee_data_matching[0].value_date"
                        :readonly="!section.rights.suggestable"
                      ></v-text-field>

                      <v-select
                        variant="outlined"
                        density="compact"
                        class="shrink"
                        v-if="field.type == 'selection'"
                        :label="field.key_name_ger"
                        :items="field.selection"
                        v-model="field.employee_data_matching[0].value_text"
                        :readonly="!section.rights.suggestable"
                      ></v-select
                    ></template>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :loading="loading" @click="updateEmployee()"
                    >Speichern</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-hover>
          <template v-for="input in endContract.array" v-if="false">
            <v-text-field
              v-if="input.type == 'text-field'"
              :label="input.label"
              type="date"
            ></v-text-field>
            <v-select
              v-if="input.type == 'select'"
              :label="input.label"
            ></v-select>
          </template> </v-card-text
      ></v-card>
      <v-dialog v-model="endContract.dialog" width="600">
        <v-card>
          <v-card-title>Arbeitsverhältnis beenden</v-card-title>
          <v-card-text>
            <v-hover v-for="section in profileStructure">
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :elevation="isHovering ? '10' : '2'"
                  rounded="xl"
                  v-if="section.id == 4"
                >
                  <v-row class="px-6 pt-12">
                    <v-col
                      v-for="field in section.employee_data_keys"
                      cols="12"
                      class="mt-n6"
                    >
                      <template
                        v-if="
                          field.id == 65 ||
                          field.id == 66 ||
                          field.id == 59 ||
                          field.id == 60
                        "
                      >
                        <v-text-field
                          variant="outlined"
                          density="compact"
                          class="shrink"
                          type="date"
                          v-if="field.type == 'date'"
                          :label="field.key_name_ger"
                          v-model="field.employee_data_matching[0].value_date"
                          :readonly="!section.rights.suggestable"
                        ></v-text-field>

                        <v-select
                          variant="outlined"
                          density="compact"
                          class="shrink"
                          clearable
                          v-if="field.type == 'selection'"
                          :label="field.key_name_ger"
                          :items="field.selection"
                          v-model="field.employee_data_matching[0].value_text"
                          :readonly="!section.rights.suggestable"
                        ></v-select
                      ></template>
                    </v-col>
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="loading"
                      @click="updateEmployee({ saveEndContractNote: true })"
                      >Speichern</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-hover>
            <template v-for="input in endContract.array" v-if="false">
              <v-text-field
                v-if="input.type == 'text-field'"
                :label="input.label"
                type="date"
              ></v-text-field>
              <v-select
                v-if="input.type == 'select'"
                :label="input.label"
              ></v-select>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-hover v-for="section in profileStructure">
        <template v-slot:default="{ isHovering, props }">
          <v-card
            class="my-2"
            v-bind="props"
            :elevation="isHovering ? '10' : '2'"
            rounded="xl"
          >
            <v-card-title class="mb-6">{{ section.name }}</v-card-title>
            <v-row class="px-6">
              <v-col
                v-for="field in section.employee_data_keys"
                cols="12"
                class="mt-n6"
              >
                <template
                  v-if="
                    field.id != 65 &&
                    field.id != 66 &&
                    field.id != 59 &&
                    field.id != 60
                  "
                >
                  <v-text-field
                    variant="outlined"
                    density="compact"
                    class="shrink"
                    v-if="field.type == 'text'"
                    :label="field.key_name_ger"
                    v-model="field.employee_data_matching[0].value_text"
                    :readonly="!section.rights.suggestable || field.is_blocked"
                  ></v-text-field>

                  <v-text-field
                    variant="outlined"
                    density="compact"
                    class="shrink"
                    type="date"
                    v-if="field.type == 'date'"
                    :label="field.key_name_ger"
                    v-model="field.employee_data_matching[0].value_date"
                    :readonly="!section.rights.suggestable"
                  ></v-text-field>

                  <v-text-field
                    variant="outlined"
                    density="compact"
                    class="shrink"
                    type="number"
                    v-if="field.type == 'number'"
                    :label="field.key_name_ger"
                    v-model="field.employee_data_matching[0].value_number"
                    :suffix="field.suffix_ger ? field.suffix_ger : null"
                    :readonly="!section.rights.suggestable"
                    :hint="field.id == 29 ? probationDates.hint : null"
                    persistent-hint
                    :class="field.id == 29 ? 'my-8' : null"
                    clearable
                  ></v-text-field>

                  <v-select
                    variant="outlined"
                    density="compact"
                    class="shrink"
                    v-if="field.type == 'selection'"
                    :label="field.key_name_ger"
                    :items="field.selection"
                    v-model="field.employee_data_matching[0].value_text"
                    :readonly="!section.rights.suggestable"
                  ></v-select>

                  <v-autocomplete
                    variant="outlined"
                    density="compact"
                    class="shrink"
                    v-if="field.type == 'id'"
                    :label="field.key_name_ger"
                    v-model="field.employee_data_matching[0].value_id"
                    :item-title="field.item_name"
                    item-value="id"
                    :items="selections[field.selection_table]"
                    :readonly="!section.rights.suggestable"
                  ></v-autocomplete>

                  <v-switch
                    color="success"
                    v-if="field.type == 'boolean'"
                    :label="field.key_name_ger"
                    v-model="field.employee_data_matching[0].value_bool"
                    :readonly="!section.rights.suggestable"
                  ></v-switch>
                </template>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-icon
                class="mr-4"
                @click="plannedSaving.field = !plannedSaving.field"
                >mdi-calendar</v-icon
              >
              <v-text-field
                density="compact"
                type="date"
                variant="outlined"
                v-if="plannedSaving.field"
                v-model="plannedSaving.date"
                class="mt-4"
              ></v-text-field>
              <v-btn
                v-if="plannedSaving.field"
                :disabled="!plannedSaving.date"
                @click="updateEmployee()"
                >Geplante Änderung speichern</v-btn
              >
              <v-btn v-else :loading="loading" @click="updateEmployee()"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col cols="12" sm="8">
      <v-card v-if="showProfile">
        <v-tabs v-model="tab" bg-color="primary" grow>
          <v-tab value="notes" v-if="role.segmentObject['notes']"
            >Notizen</v-tab
          >
          <v-tab value="emails">Emails</v-tab>
          <v-tab value="performance">Performance</v-tab>
          <v-tab value="availableDays">Verfügbarkeiten</v-tab>
          <v-tab value="files" v-if="role.segmentObject['files']"
            >Dateien</v-tab
          >
          <v-tab value="history" v-if="role.segmentObject['history']"
            >Historie</v-tab
          >
          <v-tab value="onboarding" v-if="role.segmentObject['onboarding']"
            >Onboarding</v-tab
          >
          <v-tab value="candidate" v-if="role.segmentObject['bewerberinfos']"
            >Bewerberinfo</v-tab
          >
        </v-tabs>
        <v-card-text>
          <v-window v-if="tab" v-model="tab">
            <v-window-item value="notes">
              <Notes
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></Notes>
            </v-window-item>
            <v-window-item value="emails">
              <EmailComponent
                v-if="employeeInfo.id"
                :users="users"
                :entityInfo="employeeInfo"
                entity="employees"
                :toAddress="employeeInfo.email"
              ></EmailComponent>
            </v-window-item>
            <v-window-item value="availableDays">
              <AvailableDays
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></AvailableDays>
            </v-window-item>
            <v-window-item value="files">
              <Files
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></Files>
            </v-window-item>
            <v-window-item value="history">
              <History
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></History>
            </v-window-item>
            <v-window-item value="onboarding">
              <Onboarding
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></Onboarding>
            </v-window-item>
            <v-window-item value="performance">
              <Performance
                v-if="employeeInfo.id"
                :employeeInfo="employeeInfo"
                :role="role"
              ></Performance>
            </v-window-item>
            <v-window-item value="candidate">
              <CandidateInfo
                v-if="employeeInfo.id"
                :candidateInfo="candidateInfo"
                :employeeInfo="employeeInfo"
              ></CandidateInfo> </v-window-item
          ></v-window>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12"> </v-col>
  </v-row>
</template>

<script setup>
import { mapState } from "vuex";

import TaskManagement from "@/components/Home/TaskManagement.vue";
import Notes from "@/components/Employees/ProfileComponents/Notes.vue";
import EmailComponent from "@/components/CommonComponents/EmailComponent/EmailComponent.vue";
import Files from "@/components/Employees/ProfileComponents/Files.vue";
import History from "@/components/Employees/ProfileComponents/History.vue";
import Onboarding from "@/components/Employees/ProfileComponents/Onboarding.vue";
import CandidateInfo from "@/components/Employees/ProfileComponents/CandidateInfo.vue";
import AvailableDays from "@/components/Employees/ProfileComponents/AvailableDays.vue";
import Performance from "@/components/Employees/ProfileComponents/Performance.vue";

const props = defineProps({
  employeeId: Number,
});
</script>

<script>
export default {
  data() {
    return {
      role: {},
      startEmployeeInfo: {},
      employeeInfo: {},
      newEmployee: null,
      users: [],
      loading: false,
      tab: null,
      progress: 0,
      pdfCheck: false,
      showProfile: false,
      contractEnded: false,
      profileStructure: [],
      probationDates: {},
      selections: [],
      candidateInfo: {},
      endContract: {
        dialog: false,
      },
      plannedSaving: {
        field: false,
        date: null,
      },
      chosenUser: null,
      roleRights: {},
      users: [],
    };
  },
  computed: {
    ...mapState({
      employeeChangeCheck: (state) => state.employeeChangeCheck,
    }),
  },
  watch: {
    async tab(newValue, oldValue) {
      if (newValue == "preview") {
        this.pdfCheck = true;
      } else {
        this.pdfCheck = false;
      }
    },
    beforeRouteUpdate(to, from, next) {
      // Check if the route parameter has changed
      if (to.params.id !== from.params.id) {
        // Fetch data when the parameter changes
        this.getEmployee().then(() => next());
      } else {
        // Continue with the navigation if the parameter hasn't changed
        next();
      }
    },
  },
  methods: {
    async calcProbationTime() {
      var hint = null;
      var checkDate = new Date(this.probationDates.hire_date);
      var months = this.probationDates.probation_time_months;
      if (checkDate && months) {
        checkDate.setMonth(checkDate.getMonth() + parseInt(months, 10));
        checkDate.setDate(checkDate.getDate() - 1);
        checkDate = await this.$store.dispatch("formatDate", checkDate);
        var hint = "Probezeit endet zum " + checkDate.split(" ")[0];
      }
      this.probationDates.hint = hint;
    },
    async getSelections() {
      await this.$store.dispatch("sleep");
      this.progress = 5;
      var employeeId = this.employeeId
        ? this.employeeId
        : this.$route.params.id;
      if (this.chosenUser) {
        var chosenUserId = this.chosenUser.id;
        var userEmployeeId = this.chosenUser.employees[0].id;
        var isNightshift = this.chosenUser.is_nightshift;
      } else {
        var userEmployeeId = await this.$store.dispatch("getUserEmployeeId");
      }

      this.progress = 15;
      this.$store
        .dispatch("getSupabaseEntity", {
          table: "departments",
          order: { key: "name", ascending: true },
        })
        .then((response) => {
          this.selections.departments = response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employment_types",
          order: { key: "name", ascending: true },
        })
        .then((response) => {
          this.selections.employment_types = response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "organisations",
          order: { key: "name", ascending: true },
        })
        .then((response) => {
          this.selections.organisations = response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employee_teams",
          order: { key: "name", ascending: true },
        })
        .then((response) => {
          this.selections.employee_teams = response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "cities",
          order: { key: "city", ascending: true },
        })
        .then((response) => {
          this.selections.cities = response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employees",
          select: "*,employee_data_matching!inner(*)",
          where: [
            { type: "eq", key: "status", value: "Aktiv" },
            { type: "eq", key: "employee_data_matching.key_id", value: 6 },
            {
              type: "in",
              key: "employee_data_matching.value_id",
              value: [1, 5, 6],
            },
            {
              type: "eq",
              key: "status",
              value: "Aktiv",
            },
          ],
        })
        .then((response) => {
          this.selections.employees = response.data;
        });

      this.role = await this.$store.dispatch("checkRole", {
        employeeId: employeeId,
        userEmployeeId: userEmployeeId,
        chosenUserId: chosenUserId,
        is_nightshift: isNightshift,
      });
      this.progress = 60;
      this.role.employeeId = employeeId;
      this.role.userEmployeeId = userEmployeeId;
      this.tab = null;
      this.role.segmentObject = {};
      this.role.segments.forEach((item) => {
        item.public_user_role_segment_matching.forEach((match) => {
          if (
            match.show_all_data ||
            match.show_nightshift_data ||
            match.show_own_data
          ) {
            this.role.segmentObject[item.name.toLowerCase()] = true;
          }
        });
      });

      var queryObject = {
        select:
          "*,employee_data_keys!inner(*,employee_data_matching(*,employees!inner(email)))",
        table: "employee_data_sections",
        where: [
          {
            type: "eq",
            key: "employee_data_keys.employee_data_matching.employee_id",
            value: employeeId,
          },
          {
            type: "in",
            key: "id",
            value: this.role.sections,
          },
        ],
      };
      if (!this.role.showAllEmployee && this.role.showOwnEmployee) {
        queryObject.where.push({
          type: "eq",
          key: "employee_data_keys.employee_data_matching.employees.email",
          value: this.chosenUser
            ? this.chosenUser.email
            : this.$store.state.userInfo.email,
        });
      }

      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.progress = 80;
      if (response.data.length > 0) {
        this.showProfile = true;
      } else {
        this.showProfile = false;
      }
      response.data.forEach((section) => {
        section.employee_data_keys.sort((a, b) => a.order - b.order);
        section.rights = this.role.sectionRights[section.id];
        section.employee_data_keys.forEach((key) => {
          key.selection ? key.selection.sort() : [];
          if (key.id == 29 && key.employee_data_matching[0]) {
            this.probationDates[key.key_label] =
              key.employee_data_matching[0].value_number;
          }
          if (key.id == 23 && key.employee_data_matching[0]) {
            this.probationDates[key.key_label] =
              key.employee_data_matching[0].value_date;
          }
          if (key.id == 60 && key.employee_data_matching[0]) {
            this.contractEnded = true;
          }
          if (key.employee_data_matching.length == 0) {
            var newEntry = {
              employee_id: employeeId,
              key_id: key.id,
              value: null,
              value_bool: null,
              value_date: null,
              value_id: null,
              value_text: null,
              value_number: null,
            };
            key.employee_data_matching.push(newEntry);
          }
        });
        section.employee_data_keys.sort((a, b) =>
          a.id > b.id ? 1 : b.id > a.id ? -1 : 0,
        );
      });
      this.calcProbationTime();
      this.profileStructure = response.data;
      this.startEmployeeInfo = JSON.parse(
        JSON.stringify(this.profileStructure),
      );
      var employeeRequestObject = {
        select:
          "*,employees_hotels(*,hotels(*)),recruiting_candidates(*,recruiting_job_candidates(*))",
        table: "employees",
        where: [{ type: "eq", key: "id", value: employeeId }],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        employeeRequestObject,
      );
      this.progress = 90;
      this.employeeInfo = response.data[0];
      this.candidateInfo = response.data[0].recruiting_candidates;
    },
    async changeVisibility(employeeInfo) {
      employeeInfo.is_visible = !employeeInfo.is_visible;
      await this.$store.dispatch("updateSupabaseEntity", {
        id: employeeInfo.id,
        table: "employees",
        payload: { is_visible: employeeInfo.is_visible },
      });
    },
    async addCalls(notes) {
      var calls = [];
      var hangupCauses = {
        normalClearing: "Normaler Anrufabbruch",
        busy: "Besetzt",
        cancel: "Abgebrochen",
        noAnswer: "Keine Antwort",
        congestion: "Technisches Problem",
        notFound: "Nummer nicht gefunden",
        forwarded: "Weitergeleitet",
      };
      notes.sipgate_calls.forEach((call) => {
        if (call.was_answered && call.direction == "in") {
          var action = call.action ? call.action : "Eingeganger Anruf";
          var recruitingActivities = {
            icon: "mdi-phone-incoming",
            color: "green",
          };
          var text = call.text
            ? call.text
            : `${(
                (new Date(call.end).getTime() -
                  new Date(call.start).getTime()) /
                1000 /
                60
              ).toFixed(2)} Minuten Telefonzeit`;
        }
        if (call.was_answered && call.direction == "out") {
          var action = "Ausgehender Anruf";
          var recruitingActivities = {
            icon: "mdi-phone-outgoing",
            color: "green",
          };
          var text = `${(
            (new Date(call.end).getTime() - new Date(call.start).getTime()) /
            1000 /
            60
          ).toFixed(2)} Minuten Telefonzeit`;
        }
        if (!call.was_answered && call.direction == "in") {
          var action = "Verpasster Anruf";
          var recruitingActivities = {
            icon: "mdi-phone-missed",
            color: "red",
          };
          if (call.hangup_cause == "forwarded") {
            action = "Weitergeleiteter Anruf";
            recruitingActivities.icon = "mdi-phone-forward";
            recruitingActivities.color = "warning";
          }
        }
        if (!call.was_answered && call.direction == "out") {
          var action = "Kandidat wurde nicht erreicht";
          var recruitingActivities = {
            icon: "mdi-phone-missed",
            color: "red",
          };
          if (call.hangup_cause == "forwarded") {
            action = "Weitergeleiteter Anruf";
            recruitingActivities.icon = "mdi-phone-forward";
            recruitingActivities.color = "warning";
          }
        }
        call = {
          action: action,
          modified_at: call.start,
          public_users: call.public_users,
          recruiting_activities: recruitingActivities,
          text: text,
          sipgate_id: call.sipgate_id,
        };
        calls.push(call);
      });
      return calls;
    },
    async updateEmployee(object) {
      this.loading = true;
      await this.$store.dispatch("sleep", 200);
      var upsertArray = [];
      var historyArray = [];
      var candidateObject = {};
      for (var i = 0; i < this.profileStructure.length; i++) {
        var section = this.profileStructure[i];
        for (var j = 0; j < section.employee_data_keys.length; j++) {
          var key = section.employee_data_keys[j];
          if (key.id == 29) {
            this.probationDates[key.key_label] =
              key.employee_data_matching[0].value_number;
          }
          if (key.id == 23) {
            this.probationDates[key.key_label] =
              key.employee_data_matching[0].value_date;
          }
          if (key.in_main_profile) {
            candidateObject[key.key_label] =
              key.employee_data_matching[0].value_text;
          }
          var item = key.employee_data_matching[0];
          var dataUpsertObject = {
            employee_id: item.employee_id,
            key_id: item.key_id,
          };
          if (key.type == "text" || key.type == "selection") {
            var value = item.value_text;
            var keyType = "value_text";
          } else if (key.type == "date") {
            var value = item.value_date == "" ? null : item.value_date;
            var keyType = "value_date";
            item[keyType] = value;
          } else if (key.type == "boolean") {
            var value = item.value_bool;
            var keyType = "value_bool";
          } else if (key.type == "id") {
            var value = item.value_id;
            var keyType = "value_id";
          } else if (key.type == "number") {
            var value = item.value_number;
            var keyType = "value_number";
          }
          var keyId = key.id;
          for (var k = 0; k < this.startEmployeeInfo.length; k++) {
            for (
              var l = 0;
              l < this.startEmployeeInfo[k].employee_data_keys.length;
              l++
            ) {
              var checkItem =
                this.startEmployeeInfo[k].employee_data_keys[l]
                  .employee_data_matching[0];
              if (checkItem.key_id == keyId) {
                if (checkItem[keyType] != value) {
                  delete item.employees;
                  var historyEntry = {
                    key_id: keyId,
                    employee_id: item.employee_id,
                    old_value: checkItem[keyType],
                    new_value: value,
                    key_type: keyType,
                    created_at: await this.$store.dispatch(
                      "toISOLocal",
                      new Date(),
                    ),
                  };
                  checkItem[keyType] = value;
                  if (section.rights.editable) {
                    historyEntry.changed_by_id = this.$store.state.userInfo.id;
                    dataUpsertObject[keyType] = item[keyType];
                    dataUpsertObject.modified_at = new Date().toISOString();
                  } else {
                    dataUpsertObject[keyType + "_suggestion"] = item[keyType];
                    dataUpsertObject[keyType] = checkItem[keyType];
                    historyEntry.requested_by_id =
                      this.$store.state.userInfo.id;
                  }
                  if (this.plannedSaving.date) {
                    historyEntry.accept_from_id = this.$store.state.userInfo.id;
                    historyEntry.to_save_date = this.plannedSaving.date;
                    historyEntry.requested_by_id =
                      this.$store.state.userInfo.id;
                  }
                  upsertArray.push(dataUpsertObject);
                  historyArray.push(historyEntry);
                }
              }
            }
          }
        }
      }

      this.calcProbationTime();
      if (upsertArray.length > 0) {
        if (!this.plannedSaving.date) {
          await this.$store.dispatch("upsertSupabaseEntity", {
            table: "employee_data_matching",
            payload: upsertArray,
          });
        }
        await this.$store.dispatch("createSupabaseEntity", {
          table: "employee_data_history",
          payload: historyArray,
        });
      }

      candidateObject.modified_at = new Date().toISOString();
      this.$store.dispatch("updateSupabaseEntity", {
        table: "employees",
        id: this.employeeInfo.id,
        payload: candidateObject,
      });
      if (object && object.saveEndContractNote) {
        var response = await this.$store.dispatch("getSupabaseEntity", {
          table: "employee_data_keys",
        });
        var keys = {};
        response.data.forEach((item) => {
          keys[item.id] = item;
        });
        var action = "Arbeitsverhältnis wurde auf beendet gestellt";
        var text = "";
        upsertArray.forEach((item) => {
          var keyName = keys[item.key_id].key_name_ger;
          var value = item.value_date ? item.value_date : item.value_text;
          text = text + `<div><strong>${keyName}</strong>: ${value}</div>`;
        });
        await this.$store.dispatch("createSupabaseEntity", {
          table: "notes",
          payload: {
            text: text,
            owner_id: this.$store.state.userInfo.id,
            action: action,
            employee_id: this.employeeInfo.id,
          },
        });
        this.$store.state.employeeChangeCheck = true;
      }
      this.endContract.dialog = false;
      this.loading = false;
      this.$store.dispatch("checkEmployeeStatus");
    },
  },

  async created() {
    this.loading = true;
    await this.getSelections();
    this.roleRights = await this.$store.dispatch("checkRole");
    var queryObject = {
      select: "id,email,full_name,employees(id,employee_data_matching(*))",
      table: "public_users",
      where: [
        {
          type: "eq",
          key: "employees.employee_data_matching.key_id",
          value: 6,
        },
      ],
    };
    this.users = (
      await this.$store.dispatch("getSupabaseEntity", queryObject)
    ).data;
    this.users.forEach((item) => {
      if (item.employees[0] && item.employees[0].employee_data_matching[0]) {
        var departmentId = item.employees[0].employee_data_matching[0].value_id;
        if (departmentId == 2) {
          item.is_nightshift = true;
        }
      }
    });
    this.progress = 100;
    this.loading = false;
    document.title = `${this.employeeInfo.last_name}, ${this.employeeInfo.first_name}`;
  },
  beforeUnmount() {
    document.title = "Bellcaptain Tools";
  },
};
</script>
<style>
.full-height-card {
  height: 80vh;
  overflow: auto;
  overflow-y: auto;
}
</style>
