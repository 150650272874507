<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab value="shifts">Schichten</v-tab>
      <v-tab value="hotels" v-if="$store.state.userInfo.id == 4">Hotels</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="shifts">
          <Shifts></Shifts>
        </v-window-item>
        <v-window-item value="hotels" v-if="$store.state.userInfo.id == 4">
          <HotelEntries></HotelEntries>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Shifts from "@/components/Shifts/Shifts.vue";
import HotelEntries from "@/components/Shifts/HotelEntries.vue";
</script>
<script>
export default {
  data() {
    return {
      tab: "shifts",
    };
  },
  methods: {},
  async mounted() {},
};
</script>
