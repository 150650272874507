<template>
  <v-card flat v-if="report.dbObject.id">
    <v-dialog v-model="tutorial.dialog" max-width="600px">
      <v-card>
        <v-card-title
          class="bg-primary text-white d-flex justify-space-between align-center"
        >
          <span>{{ tutorial.task.task }}</span>
          <v-icon @click="tutorial.dialog = false" class="cursor-pointer"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text
          class="pa-4 px-8"
          v-html="tutorial.task.explanation"
        ></v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="mx-2 my-4 pa-4" elevation="4" rounded="lg">
      <v-row class="text-h5 mb-4" align="center">
        <v-col cols="8" class="font-weight-bold text-primary">
          Leistungsnachweis - {{ shift.hotels.name }}
        </v-col>
        <v-col cols="4" class="text-right font-weight-medium">
          Datum: {{ date }}
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="my-4">
        <v-col cols="12">
          <div v-if="!isCategorisedView">
            <!-- Display tasks as before -->
            <v-list density="compact">
              <v-list-item
                v-for="task in report.dbObject.json"
                :key="task.id"
                class="mb-1"
                elevation="2"
                variant="outlined"
                :class="task.checked ? 'bg-green lighten-4' : null"
                hover
                rounded="lg"
              >
                <v-list-item-title
                  class="d-flex align-center justify-space-between"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      class="mr-2 cursor-pointer"
                      :color="task.checked ? 'white' : 'primary'"
                      @click="() => openTutorial(task)"
                    >
                      mdi-help-circle
                    </v-icon>
                    <span class="font-weight-medium">{{ task.task }}</span>
                  </div>
                  <v-fade-transition mode="out-in">
                    <v-icon
                      v-if="task.checked"
                      size="x-large"
                      icon="mdi-checkbox-marked-circle-outline"
                      class="cursor-pointer icon-animation"
                      :color="task.checked ? 'white' : 'primary'"
                    ></v-icon>
                    <v-icon
                      v-else
                      size="x-large"
                      icon="mdi-checkbox-blank-circle-outline"
                      class="cursor-pointer icon-animation"
                      :color="task.checked ? 'green darken-2' : 'primary'"
                    ></v-icon>
                  </v-fade-transition>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="task.checked"
                  class="mt-2 text--secondary"
                >
                  {{ task.employee }} am
                  {{
                    new Date(task.date).toLocaleDateString("de", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <!-- Display tasks grouped by category -->
            <div
              v-for="categoryData in getCategorisedView(report)"
              :key="categoryData.category.name"
            >
              <div v-if="categoryData.category.name === 'Leistungsnachweis'">
                <!-- Display tasks directly without a card -->
                <v-list density="compact">
                  <v-list-item
                    v-for="task in categoryData.tasks"
                    :key="task.id"
                    class="mb-1"
                    elevation="2"
                    variant="outlined"
                    :class="task.checked ? 'bg-green lighten-4' : null"
                    hover
                    rounded="lg"
                  >
                    <v-list-item-title
                      class="d-flex align-center justify-space-between"
                    >
                      <div class="d-flex align-center">
                        <v-icon
                          class="mr-2 cursor-pointer"
                          :color="task.checked ? 'white' : 'primary'"
                          @click="() => openTutorial(task)"
                        >
                          mdi-help-circle
                        </v-icon>
                        <span class="font-weight-medium">{{ task.task }}</span>
                      </div>
                      <v-fade-transition mode="out-in">
                        <v-icon
                          v-if="task.checked"
                          size="x-large"
                          icon="mdi-checkbox-marked-circle-outline"
                          class="cursor-pointer icon-animation"
                          :color="task.checked ? 'white' : 'primary'"
                        ></v-icon>
                        <v-icon
                          v-else
                          size="x-large"
                          icon="mdi-checkbox-blank-circle-outline"
                          class="cursor-pointer icon-animation"
                          :color="task.checked ? 'green darken-2' : 'primary'"
                        ></v-icon>
                      </v-fade-transition>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="task.checked"
                      class="mt-2 text--secondary"
                    >
                      {{ task.employee }} am
                      {{
                        new Date(task.date).toLocaleDateString("de", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
              <div v-else>
                <!-- Display tasks inside a card with the category title -->
                <v-card class="mb-4" elevation="2">
                  <v-card-title>{{ categoryData.category.name }}</v-card-title>
                  <v-card-text>
                    <v-list density="compact">
                      <v-list-item
                        v-for="task in categoryData.tasks"
                        :key="task.id"
                        class="mb-1"
                        elevation="2"
                        variant="outlined"
                        :class="task.checked ? 'bg-green lighten-4' : null"
                        hover
                        rounded="lg"
                      >
                        <v-list-item-title
                          class="d-flex align-center justify-space-between"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                              class="mr-2 cursor-pointer"
                              :color="task.checked ? 'white' : 'primary'"
                              @click="() => openTutorial(task)"
                            >
                              mdi-help-circle
                            </v-icon>
                            <span class="font-weight-medium">{{
                              task.task
                            }}</span>
                          </div>
                          <v-fade-transition mode="out-in">
                            <v-icon
                              v-if="task.checked"
                              @click="completeTask(task)"
                              size="x-large"
                              icon="mdi-checkbox-marked-circle-outline"
                              class="cursor-pointer icon-animation"
                              :color="task.checked ? 'white' : 'primary'"
                            ></v-icon>
                            <v-icon
                              v-else
                              @click="completeTask(task)"
                              size="x-large"
                              icon="mdi-checkbox-blank-circle-outline"
                              class="cursor-pointer icon-animation"
                              :color="
                                task.checked ? 'green darken-2' : 'primary'
                              "
                            ></v-icon>
                          </v-fade-transition>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="task.checked"
                          class="mt-2 text--secondary"
                        >
                          {{ task.employee }} am
                          {{
                            new Date(task.date).toLocaleDateString("de", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="my-4">
        <v-col cols="12" class="mb-4">
          <v-card class="pa-4" elevation="2" rounded="lg">
            <div class="font-weight-bold mb-2">
              Dienstleistung erbracht durch Bellcaptain Mitarbeiter
            </div>
            <div class="mb-4 text-dark">
              Leistung erbracht durch {{ shift.employees.name }}
            </div>
            <div class="mb-4 font-weight-medium text-dark">Unterschrift</div>
            <v-card
              variant="outlined"
              height="200px"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="report.dbObject.is_signed"
                :src="report.dbObject.signature_employee"
                class="ma-5"
                height="100"
              ></v-img>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-4">
        <v-col cols="12">
          <v-card class="pa-4" elevation="2" rounded="lg">
            <div class="font-weight-bold mb-2">
              Abnahme erfolgt durch Mitarbeiter des Kunden
            </div>
            <v-text-field
              v-model="hotelCheck.name"
              label="Name des Abnehmers"
              variant="outlined"
              :disabled="report.dbObject.is_signed"
            ></v-text-field>
            <v-card class="ma-4" flat>
              <v-list density="compact">
                <v-list-item
                  v-for="task in hotelCheck.tasks"
                  :key="task.id"
                  class="mb-1"
                  elevation="3"
                  :class="task.checked ? 'bg-green lighten-4' : null"
                  hover
                  rounded="lg"
                  variant="outlined"
                >
                  <v-list-item-title
                    class="d-flex align-center justify-space-between"
                  >
                    <div class="d-flex align-center">
                      <span class="font-weight-medium">{{ task.task }}</span>
                    </div>
                    <v-fade-transition mode="out-in">
                      <v-icon
                        v-if="task.checked"
                        size="x-large"
                        icon="mdi-checkbox-marked-circle-outline"
                        class="cursor-pointer icon-animation"
                        :color="task.checked ? 'white' : 'primary'"
                      ></v-icon>
                      <v-icon
                        v-else
                        size="x-large"
                        icon="mdi-checkbox-blank-circle-outline"
                        class="cursor-pointer icon-animation"
                        :color="task.checked ? 'green darken-2' : 'primary'"
                      ></v-icon>
                    </v-fade-transition>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
            <v-textarea
              label="Bemerkungen"
              :disabled="report.dbObject.is_signed"
              v-model="hotelCheck.comment"
            ></v-textarea>
            <div class="mb-4 font-weight-medium text-dark">Unterschrift</div>
            <v-card
              variant="outlined"
              height="200px"
              class="d-flex align-center justify-center"
            >
              <v-img
                v-if="report.dbObject.is_signed"
                :src="report.dbObject.signature_hotel"
                class="ma-5"
                height="100"
              ></v-img>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    shift: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCategorisedView: false,
      employeeInfo: {},
      signature1: null,
      signature2: null,
      loading: false,
      state1: {
        count: 0,
        option: {
          penColor: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255,255,255)",
        },
        disabled: false,
      },
      state2: {
        count: 0,
        option: {
          penColor: "rgb(0, 0, 0)",
          backgroundColor: "rgb(255,255,255)",
        },
        disabled: false,
      },
      report: { dbObject: {} },
      hotel: {},
      date: "",
      tutorial: {},
      hotelCheck: {
        tasks: [
          { task: "Leistung erbracht" },
          { task: "Alle relevanten Schlüssel übergeben" },
          { task: "Kasse(n) ohne Differenz übergeben" },
        ],
        name: null,
      },
    };
  },
  methods: {
    async getReport() {
      let response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotel_performance_records",
        select: "*",
        where: [{ type: "eq", key: "id", value: this.reportId }],
      });
      this.report.dbObject = response.data[0];
      this.hotel = response.data[0].hotels;
      if (this.report.dbObject.hotel_person_info) {
        this.hotelCheck = this.report.dbObject.hotel_person_info;
      }
      this.report.dbObject.json.sort((a, b) => a.order - b.order);
    },
    getCategorisedView(reportObject) {
      let sortedTasks = [];
      let categoryMap = {};

      reportObject.dbObject.json.forEach((task) => {
        let categoryName = task.category.name;
        if (!categoryMap[categoryName]) {
          categoryMap[categoryName] = {
            category: task.category,
            tasks: [],
          };
        }
        categoryMap[categoryName].tasks.push(task);
      });

      // Convert categoryMap to an array
      sortedTasks = Object.values(categoryMap);

      // Sort categories by category.order, with null being last
      sortedTasks.sort((a, b) => {
        if (a.category.order === null && b.category.order === null) {
          return 0;
        } else if (a.category.order === null) {
          return 1;
        } else if (b.category.order === null) {
          return -1;
        } else {
          return a.category.order - b.category.order;
        }
      });

      // Sort tasks in categories
      sortedTasks.forEach((category) => {
        category.tasks.sort((a, b) => a.order - b.order);
      });

      return sortedTasks ?? [];
    },
    openTutorial(task) {
      this.tutorial.task = task;
      this.tutorial.dialog = true;
    },
    transformDate(date) {
      var onlyDate = date.split("T")[0];
      var time = date.split("T")[1].split(":");
      time = parseInt(time[0], 10) + 2 + ":" + time[1];
      date =
        onlyDate.split("-")[2] +
        "." +
        onlyDate.split("-")[1] +
        "." +
        onlyDate.split("-")[0] +
        " " +
        time;
      return date;
    },
  },
  async mounted() {
    await this.getReport();

    const sortedTasks = this.getCategorisedView(this.report);
    this.isCategorisedView = Object.keys(sortedTasks).length > 1;
    console.log(this.isCategorisedView);
    let dateParts = this.shift.date.split("-");
    this.date = dateParts[2] + "." + dateParts[1] + "." + dateParts[0];
  },
};
</script>

<style scoped>
.icon-animation-enter-active,
.icon-animation-leave-active {
  transition: transform 0.3s ease;
}
.icon-animation-enter, .icon-animation-leave-to /* .icon-animation-leave-active in <2.1.8 */ {
  transform: rotate(360deg);
}
</style>
