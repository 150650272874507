<template>
  <v-row class="ma-1">
    <v-col class="text-h4" cols="12">
      ToDo Mitarbeiterchecks
      <v-icon
        @click="showChecklist = !showChecklist"
        :icon="showChecklist ? 'mdi-eye-off' : 'mdi-eye'"
      ></v-icon>
    </v-col>
    <template v-if="showChecklist">
      <v-col cols="12">
        <v-btn-toggle v-model="chosenCity">
          <v-btn
            color="indigo"
            v-for="city in cityFilter"
            :key="city.city"
            :value="city.city"
          >
            {{ city.city }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <template v-for="toCheck in checklist">
        <v-col
          v-if="checkListItemCheck(toCheck)"
          xxl="3"
          xl="3"
          lg="4"
          md="6"
          sm="6"
          xs="6"
          cols="12"
        >
          <v-card elevation="5">
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <div>{{ toCheck.name }}</div>
                  <div class="text-caption">
                    Heute im
                    <b>{{ toCheck.hotel + " / " + toCheck.hotel_city }}</b>
                  </div>
                  <v-chip
                    @click="router.push(`employeeCheck/${check.id}`)"
                    variant="elevated"
                    size="x-small"
                    v-for="check in toCheck.month_checks"
                    :color="check.is_passed ? 'success' : 'error'"
                    >{{
                      `Check am ${new Date(check.date).toLocaleDateString(
                        "de",
                      )} durchgeführt`
                    }}</v-chip
                  >
                </v-col>
                <v-col cols="4" align="right">
                  <v-avatar
                    v-if="toCheck.score == 0"
                    class="ma-2"
                    size="40"
                    color="success"
                    ><v-icon>mdi-check</v-icon></v-avatar
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </template>
  </v-row>
  <v-row class="mx-12">
    <v-col cols="3">
      <v-select
        v-model="chosenDate"
        :items="monthFilter"
        label="Datum"
        @update:model-value="getEmployeeChecks"
        dense
      ></v-select>
    </v-col>
    <v-col cols="3">
      <v-col cols="12">
        <v-btn
          :color="chosenCity == city.city ? 'indigo' : null"
          v-for="city in dbCityArray"
          :key="city.city"
          :value="city.id"
          @click="
            chosenCity = city.city;
            getEmployeeChecks();
          "
          size="small"
        >
          {{ city.city }}
        </v-btn>
      </v-col>
    </v-col>
    <v-col cols="3">
      <v-text-field v-model="search" label="Mitarbeiter" dense></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-switch
        v-model="showOnlyEmployeeWithChecks"
        label="Nur Mitarbeiter mit Checks"
        @input="getEmployeeChecks()"
      ></v-switch>
    </v-col>
    <v-data-table
      :search="search"
      :items="checks"
      :items-per-page="-1"
      :headers="headers"
      :custom-filter="filterEmployeeNames"
    >
      <template v-slot:item.employee="{ item }">
        <router-link :to="'/Mitarbeiter/' + item.id">
          {{
            `${item.employee.last_name}, ${item.employee.first_name}`
          }}</router-link
        >
      </template>
      <template v-slot:item.checks="{ item }">
        <v-chip
          variant="elevated"
          v-for="check in item.checks"
          size="small"
          :color="check.is_passed ? 'success' : 'error'"
          @click="openEmployeeCheck(check)"
        >
          {{ check.hotels.short_name }} -
          {{ new Date(check.date).toLocaleDateString("de") }}
        </v-chip>
      </template>
    </v-data-table>
  </v-row>
  <v-dialog v-model="employeeCheck.dialog">
    <v-card>
      <EmployeeCheck
        v-if="employeeCheck.dialog"
        :employeeCheckProp="employeeCheck.employeeCheck"
        @closeDialog="closeEmployeeCheckDialog"
      ></EmployeeCheck>
    </v-card>
  </v-dialog>
</template>

<script setup>
import EmployeeCheck from "@/components/Employees/ProfileComponents/Performance/EmployeeCheck.vue";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";

var store = useStore();
let checks = ref([]);
let checklist = ref([]);
let hotelFilter = ref([]);
let chosenCity = ref(null);
let showChecklist = ref(false);
let chosenDate = ref(null);
let search = ref(null);
let showOnlyEmployeeWithChecks = ref(false);
let cityFilter = ref([]);
let dbCityArray = ref([]);
let headers = ref([
  {
    title: "Mitarbeiter",
    key: "employee",
    value: "employee",
    sort: (a, b) => a.last_name.localeCompare(b.last_name),
  },
  { title: "Stadt", key: "city", value: "city" },
  { title: "Checks", key: "checks", value: "checks" },
]);
let employeeCheck = ref({
  dialog: false,
  employeeCheck: {},
});

let monthFilter = ref([]);

const closeEmployeeCheckDialog = () => {
  employeeCheck.value.dialog = false;
};

const filterEmployeeNames = (value, search) => {
  if (!value || !search) return false;
  const fullName = `${value.last_name} ${value.first_name}`.toLowerCase();
  return fullName.includes(search.toLowerCase());
};

const getToCheckList = async () => {
  var employeeObject = {};
  const today = new Date();
  const dateMonth = today.getMonth();
  const employeeResponse = await store.dispatch("getSupabaseEntity", {
    select: "id,name",
    table: "employees",
  });
  employeeResponse.data.forEach((employee) => {
    employeeObject[employee.id] = employee;
  });
  const response = await store.dispatch("getSupabaseEntity", {
    table: "view_check_employee_forms",
  });
  let hotelFilterSet = new Set();
  let cityFilterSet = new Set();
  response.data.forEach((employee) => {
    employee.month_checks = employee.month_checks.filter(
      (check) => new Date(check.date).getMonth() == dateMonth,
    );
    employee.name = employeeObject[employee.employee_id].name;
    if (
      employee.month_checks.length == 2 ||
      (employee.month_checks.length == 1 &&
        employee.employment_type != "Festanstellung")
    ) {
      employee.score = 0;
    }
    if (
      (employee.month_checks.length == 1 &&
        employee.employment_type == "Festanstellung") ||
      (employee.month_checks.length == 0 &&
        employee.employment_type != "Festanstellung")
    ) {
      employee.score = 1;
    }
    if (
      employee.month_checks.length == 0 &&
      employee.employment_type == "Festanstellung"
    ) {
      employee.score = 2;
    }
    hotelFilterSet.add(employee.hotel);
    cityFilterSet.add(employee.hotel_city);
  });
  hotelFilter.value = Array.from(hotelFilterSet);
  for (const x of cityFilterSet) {
    cityFilter.value.push({ city: x, chosen: true });
  }

  response.data.sort((a, b) => {
    if (a.score > b.score) {
      return -1;
    }
    if (a.score < b.score) {
      return 1;
    }
    return 0;
  });
  checklist.value = response.data;
};

const openEmployeeCheck = async (check) => {
  const response = await store.dispatch("getSupabaseEntity", {
    select:
      "*,hotels(name,short_name,city),employee:employees!employee_check_form_employee_id_fkey(name),inspector:employees!employee_check_form_inspector_id_fkey(name)",
    table: "employee_check_form",
    where: [{ type: "eq", key: "id", value: check.id }],
  });
  let checkObject = response.data[0];
  checkObject.hotel = check.hotels.name;
  checkObject.employee = check.employee.name;
  checkObject.inspector = check.inspector.name;
  employeeCheck.value.employeeCheck = checkObject;
  employeeCheck.value.dialog = true;
};

const checkListItemCheck = (toCheck) => {
  const scoreCheck = toCheck.score >= 0;
  let cityCheck = !chosenCity.value || toCheck.hotel_city == chosenCity.value;
  return scoreCheck && cityCheck;
};

const getEmployeeChecks = async () => {
  checks.value = [];
  const queryObject = {
    select:
      "id,employee_id,date,is_signed,is_passed,hotels(name,short_name,city),employee:employees!employee_check_form_employee_id_fkey(first_name,last_name,name,employee_data_matching(*)),inspector:employees!employee_check_form_inspector_id_fkey(name)",
    table: "employee_check_form",
    where: [
      { type: "is", key: "is_template", value: false },
      { type: "eq", key: "employee.employee_data_matching.key_id", value: 5 },
    ],
  };
  const response = await store.dispatch("getSupabaseEntity", queryObject);
  var employeeObject = {};
  var today = new Date();
  for (var i = 0; i < 3; i++) {
    //headers.value.push({ title: today.getMonth() + "." + today.getFullYear(), key: today.getMonth() + "." + today.getFullYear(), value:today.getMonth() + "." + today.getFullYear()});
    today.setMonth(today.getMonth() + 1);
  }
  console.log(response.data);

  let monthFilterSet = new Set();
  response.data.forEach((item) => {
    let city;
    let dateString =
      new Date(item.date).toLocaleDateString("de-DE", { month: "long" }) +
      " " +
      new Date(item.date).getFullYear();
    item.dateString = dateString;
    monthFilterSet.add(dateString);
    if (!employeeObject[item.employee_id]) {
      let cityId = item.employee.employee_data_matching[0].value_id;
      city = dbCityArray.value.find((city) => city.id == cityId)
        ? dbCityArray.value.find((city) => city.id == cityId).city
        : null;
      employeeObject[item.employee_id] = {
        id: item.employee_id,
        employee: {
          name: item.employee.name,
          first_name: item.employee.first_name,
          last_name: item.employee.last_name,
        },
        city: city,
        checks: [],
      };
    }

    if (!chosenDate.value || item.dateString == chosenDate.value) {
      employeeObject[item.employee_id].checks.push(item);
    }
  });
  monthFilter.value = Array.from(monthFilterSet);
  var keys = Object.keys(employeeObject);
  for (var i = 0; i < keys.length; i++) {
    employeeObject[keys[i]].checks.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    if (!chosenCity.value || employeeObject[keys[i]].city == chosenCity.value) {
      checks.value.push(employeeObject[keys[i]]);
    }
  }
  if (showOnlyEmployeeWithChecks.value) {
    checks.value = checks.value.filter((item) => item.checks.length > 0);
  }
};

onMounted(async () => {
  const response = await store.dispatch("getSupabaseEntity", {
    table: "cities",
    where: [{ type: "is", key: "hotel_city", value: true }],
  });
  dbCityArray.value = response.data;
  await getToCheckList();
  chosenDate.value = new Date().toLocaleDateString("de-DE", {
    month: "long",
    year: "numeric",
  });
  await getEmployeeChecks();
});
</script>
