<template>
  <v-card variant="flat">
    <v-card-text>
      <v-btn
        @click="isSenior = true"
        :color="isSenior ? 'green' : null"
        class="mx-2"
        >Senior</v-btn
      >
      <v-btn @click="isSenior = false" :color="!isSenior ? 'green' : null"
        >Clerk</v-btn
      >
      <v-card v-if="isInitialLoading" class="my-3 px-1 py-3" variant="flat">
        <v-skeleton-loader type="image" />
        <v-skeleton-loader type="image" class="my-3" />
        <v-skeleton-loader type="image" />
      </v-card>
      <v-form v-else ref="form" v-model="valid" class="px-2">
        <v-card
          v-for="(category, index) in categoryAndTaskForm"
          :key="category.selectedCategory?.id"
          class="my-3"
          elevation="5"
        >
          <v-card-text v-if="!category.delete">
            <!-- Category Row -->
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-combobox
                  v-model="category.selectedCategory"
                  label="Kategorie"
                  item-title="name"
                  item-value="id"
                  readonly
                  return-object
                  variant="solo-filled"
                  :rules="[
                    (v) => !!v?.name.trim() || 'Kategorie ist erforderlich',
                  ]"
                  :class="
                    category.hotel_specific_tasks.length === 0 ? 'mb-5' : ''
                  "
                ></v-combobox>
              </v-col>
            </v-row>

            <!-- Tasks -->
            <draggable
              v-model="category.hotel_specific_tasks"
              group="tasks"
              :animation="200"
              item-key="name"
            >
              <template #item="{ element: task, index: taskIndex }">
                <v-row
                  :key="task.selectedHotelTask?.id"
                  v-if="!task.delete && task.is_senior === isSenior"
                  class="my-n4"
                >
                  <!-- Task Name -->
                  <v-col cols="auto">
                    <v-icon size="x-large" class="mt-2 mr-n6 ml-n2" @click=""
                      >mdi-drag</v-icon
                    >
                  </v-col>
                  <v-col cols="6" md="4" class="mr-0">
                    <v-combobox
                      v-model="task.selectedHotelTask"
                      v-model:search="taskInput"
                      label="Aufgabe"
                      density="compact"
                      :items="allTasks"
                      item-title="name"
                      item-value="id"
                      height="50px"
                      :hide-no-data="!taskInput"
                      :readonly="!!task.selectedHotelTask?.id"
                      return-object
                      hide-selected
                      variant="outlined"
                      :rules="[
                        (v) => !!v?.name.trim() || 'Aufgabe ist erforderlich',
                      ]"
                      @update:modelValue="
                        (value) =>
                          onTaskModelUpdate({
                            value,
                            categoryIndex: index,
                            taskIndex,
                          })
                      "
                      @keydown.enter="
                        onEnterTask({
                          categoryIndex: index,
                          taskIndex,
                        })
                      "
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            <kbd>↵ Enter</kbd> drücken, um
                            <strong>{{ taskInput }}</strong> hinzuzufügen
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="4" md="3">
                    <v-switch
                      v-model="task.is_in_training_test"
                      color="primary"
                      align-self="start"
                      hide-details
                      :style="`margin-top:
                    ${task.is_in_training_test ? '-15px' : '-9px'};`"
                    >
                      <template v-slot:label>
                        <span
                          :style="`opacity: ${
                            task.is_in_training_test ? '100%' : '50%'
                          };`"
                        >
                          {{
                            task.is_in_training_test
                              ? "In Abnahme"
                              : "Nicht in Abnahme"
                          }}
                        </span>
                        <!-- Icon Checkboxes  -->
                        <div
                          class="icon-checkbox-wrapper mt-2"
                          v-if="task.is_in_training_test"
                        >
                          <v-icon style="margin-left: 17px">mdi-alert</v-icon>
                          <v-checkbox
                            class="mt-n5"
                            v-model="task.is_crucial"
                            hide-details
                          >
                            <v-tooltip
                              activator="parent"
                              location="bottom"
                              open-delay="300"
                              >Sicherheitsrelevante Frage: <br />
                              Bei nicht korrekter Ausführung ist eine weitere
                              Einarbeitung zwingend notwendig.</v-tooltip
                            ></v-checkbox
                          >
                        </div>
                        <div
                          class="icon-checkbox-wrapper mt-2"
                          v-if="task.is_in_training_test"
                        >
                          <span style="white-space: nowrap; margin-left: 17px"
                            >ZW</span
                          >
                          <v-checkbox
                            class="mt-n5"
                            v-model="task.alsoInIntermediateCheck"
                            hide-details
                          >
                            <v-tooltip
                              activator="parent"
                              location="bottom"
                              open-delay="300"
                              >Auch in Zwischenstand</v-tooltip
                            ></v-checkbox
                          >
                        </div>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="1" xs="1" class="hidden-md-and-up ml-4">
                    <v-btn
                      icon
                      size="small"
                      flat
                      class="mr-1"
                      @click="deleteTask(index, taskIndex)"
                    >
                      <v-icon>mdi-close</v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Aufgabe Löschen
                      </v-tooltip>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" class="mr-n8">
                    <v-expansion-panels>
                      <v-expansion-panel title="Erklärung">
                        <v-expansion-panel-text>
                          <v-card class="quill-container">
                            <QuillEditor
                              toolbar="essential"
                              v-model:content="task.explanation"
                              contentType="html"
                            />
                          </v-card>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <v-col
                    cols="2"
                    xs="2"
                    md="1"
                    class="px-0 hidden-sm-and-down"
                    align="end"
                  >
                    <v-btn
                      icon
                      size="small"
                      flat
                      class="mr-1"
                      @click="deleteTask(index, taskIndex)"
                    >
                      <v-icon>mdi-close</v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Aufgabe Löschen
                      </v-tooltip>
                    </v-btn>
                  </v-col>
                  <v-divider
                    class="horizontal-divider mb-6 hidden-md-and-up"
                    :thickness="2"
                  ></v-divider>
                </v-row>
              </template>
            </draggable>

            <!-- Add Task Button -->
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn
                  size="x-small"
                  icon
                  @click="addTask(index)"
                  class="ml-n2"
                >
                  <v-icon>mdi-plus</v-icon>
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="300"
                    >Neue Aufgabe hinzufügen</v-tooltip
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row no-gutters>
          <v-spacer />
          <v-col cols="12" md="3" :align="mdAndUp ? 'end' : 'start'">
            <v-btn
              v-if="categoryAndTaskForm.length > 0"
              @click="submitForm"
              :color="!valid ? 'grey' : 'success'"
              :loading="submitting"
              :disabled="submitting"
            >
              <v-icon class="mr-1">mdi-content-save</v-icon>
              <v-tooltip activator="parent" location="top" v-if="!valid">
                Ein oder mehrere Felder sind leer
              </v-tooltip>
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar
        v-model="snackbar.isOpen"
        timeout="3000"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { QuillEditor } from "@vueup/vue-quill";
import draggable from "vuedraggable";

const { mdAndUp } = useDisplay();
const props = defineProps({
  hotelId: Number,
});
</script>

<script>
export default {
  data() {
    return {
      isSenior: true,
      isInitialLoading: true,
      submitting: false,
      form: null,
      valid: true,
      categoryAndTaskForm: [],
      allTasks: [],
      employeeId: null,
      categoryInput: "",
      taskInput: "",
      snackbar: {
        isOpen: false,
        text: "",
        color: null,
      },
    };
  },
  methods: {
    prepareDBTaskForForm(task) {
      return {
        id: task.id,
        selectedHotelTask: {
          id: task.task_id,
          name: task.hotel_tasks.name,
        },
        order: task.order,
        explanation: task.explanation,
        alsoInIntermediateCheck: !task.is_only_final,
        created_by_id: task.created_by_id,
        delete: false,
        is_crucial: task.is_crucial,
        is_senior: task.is_senior,
        is_in_training_test: task.is_in_training_test,
      };
    },
    async initializeForm() {
      let formConfigurations = null;
      let allTasksOptionsResponse = null;

      try {
        const formStructure = await this.$store.dispatch("getSupabaseEntity", {
          select: "*,hotel_specific_tasks!inner(*, hotel_tasks(*))",
          table: "hotel_task_categories",
          order: {
            key: "order",
            ascending: true,
          },
          where: [
            {
              type: "eq",
              key: "hotel_specific_tasks.hotel_id",
              value: this.hotelId,
            },
            {
              type: "is",
              key: "hotel_specific_tasks.deleted_at",
              value: null,
            },
          ],
        });

        formConfigurations = [
          {
            selectedCategory: {
              id: 17,
              name: "Aufgaben vor EOD",
            },
            is_general: false,
            order: 1,
            delete: false,
            hotel_specific_tasks:
              formStructure.data
                ?.find((category) => category.name === "Aufgaben vor EOD")
                ?.hotel_specific_tasks?.sort((a, b) => a.order - b.order)
                .map(this.prepareDBTaskForForm) ?? [],
          },
          {
            selectedCategory: {
              id: 18,
              name: "Aufgaben zum EOD",
            },
            is_general: false,
            order: 2,
            delete: false,
            hotel_specific_tasks:
              formStructure.data
                ?.find((category) => category.name === "Aufgaben zum EOD")
                ?.hotel_specific_tasks?.sort((a, b) => a.order - b.order)
                .map(this.prepareDBTaskForForm) ?? [],
          },
          {
            selectedCategory: {
              id: 19,
              name: "Aufgaben nach EOD",
            },
            is_general: false,
            order: 3,
            delete: false,
            hotel_specific_tasks:
              formStructure.data
                ?.find((category) => category.name === "Aufgaben nach EOD")
                ?.hotel_specific_tasks?.sort((a, b) => a.order - b.order)
                .map(this.prepareDBTaskForForm) ?? [],
          },
          {
            selectedCategory: {
              id: 13,
              name: "Bitte kategorisieren",
            },
            is_general: false,
            order: 4,
            delete: false,
            hotel_specific_tasks:
              formStructure.data
                ?.filter(
                  (category) =>
                    ![
                      "Aufgaben vor EOD",
                      "Aufgaben zum EOD",
                      "Aufgaben nach EOD",
                    ].includes(category.name),
                )
                ?.flatMap((category) => category.hotel_specific_tasks)
                ?.sort((a, b) => a.order - b.order)
                .map(this.prepareDBTaskForForm) ?? [],
          },
        ];

        allTasksOptionsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            select: "*",
            table: "hotel_tasks",
            where: [
              {
                type: "in",
                key: "category_id",
                value: formConfigurations.map(
                  (category) => category.selectedCategory.id,
                ),
              },
            ],
          },
        );
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Laden des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      } finally {
        this.isInitialLoading = false;
      }

      return {
        formConfigurations,
        allTasksOptionsResponse,
      };
    },
    addTask(categoryIndex) {
      const currentCategory = this.categoryAndTaskForm[categoryIndex];

      this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks.push({
        id: null,
        selectedHotelTask: {
          id: null,
          name: "",
        },
        hotel_id: this.hotelId,
        explanation: "",
        order:
          this.categoryAndTaskForm[categoryIndex]?.hotel_specific_tasks
            ?.length + 1,
        is_crucial: false,
        alsoInIntermediateCheck: true,
        delete: false,
        is_senior: this.isSenior,
        created_by_id: this.employeeId,
        is_in_training_test: false,
      });
    },
    deleteTask(categoryIndex, taskIndex) {
      if (
        this.categoryAndTaskForm[categoryIndex] &&
        this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks[taskIndex]
      ) {
        this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks[
          taskIndex
        ].delete = true;
      }
    },
    onTaskModelUpdate({ value, categoryIndex, taskIndex }) {
      if (typeof value === "string") {
        this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks[
          taskIndex
        ].selectedHotelTask = {
          id: null,
          name: value,
        };
      } else if (value && value.id) {
        // User selected an existing task
        this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks[
          taskIndex
        ].selectedHotelTask = value;
      } else {
        // User cleared the input
        this.categoryAndTaskForm[categoryIndex].hotel_specific_tasks[
          taskIndex
        ].selectedHotelTask = {
          id: null,
          name: "",
        };
      }
    },
    onEnterTask({ categoryIndex, taskIndex }) {
      if (!this.taskInput) return;
      this.onTaskModelUpdate({
        value: this.taskInput,
        categoryIndex,
        taskIndex,
      });
      this.taskInput = "";
    },
    async refreshForm() {
      const { formConfigurations, allTasksOptionsResponse } =
        await this.initializeForm();

      this.categoryAndTaskForm = formConfigurations;
      this.allTasks = allTasksOptionsResponse.data || [];
    },
    submitForm() {
      if (this.form) {
        this.form.validate();
      }
      if (this.valid) {
        this.saveFormConfigurations();
      }
    },
    async deleteRecords() {
      for (const category of this.categoryAndTaskForm) {
        category.hotel_specific_tasks
          .filter((task) => task.delete && task.id)
          .forEach(async (task) => {
            await this.$store.dispatch("updateSupabaseEntity", {
              table: "hotel_specific_tasks",
              payload: { deleted_at: new Date().toISOString(), order: null },
              id: task.id,
            });
          });
      }

      this.categoryAndTaskForm = this.categoryAndTaskForm
        .filter((category) => !category.delete)
        .map((category) => ({
          ...category,
          hotel_specific_tasks: category.hotel_specific_tasks.filter(
            (task) => !task.delete,
          ),
        }));
    },
    async insertNewTasks() {
      const newTasks = this.categoryAndTaskForm.flatMap((category) => {
        return category.hotel_specific_tasks
          .filter((task) => !task.selectedHotelTask.id)
          .map((task) => ({
            name: task.selectedHotelTask.name.trim(),
            category_id: category.selectedCategory.id,
          }));
      });

      if (newTasks.length) {
        const { data: insertedTasks } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "hotel_tasks",
            payload: newTasks,
            returnFullResponse: true,
          },
        );

        insertedTasks.forEach((insertedTask) => {
          this.categoryAndTaskForm
            .find(
              (category) =>
                category.selectedCategory.id === insertedTask.category_id,
            )
            .hotel_specific_tasks.find(
              (task) => task.selectedHotelTask.name === insertedTask.name,
            ).selectedHotelTask.id = insertedTask.id;
        });
      }
    },
    async updateHotelSpecificTasks() {
      const hotelSpecificTasks = this.categoryAndTaskForm.flatMap(
        (category) => {
          return category.hotel_specific_tasks.map((task, index) => ({
            ...(task.id && { id: task.id }),
            category_id: category.selectedCategory.id,
            hotel_id: task.hotel_id ?? this.hotelId,
            task_id: task.selectedHotelTask.id,
            is_only_final: task.is_in_training_test
              ? !task.alsoInIntermediateCheck
              : null,
            explanation: task.explanation,
            is_crucial: task.is_in_training_test ? task.is_crucial : null,
            is_senior: task.is_senior,
            order: index + 1,
            created_by_id: task.created_by_id ?? this.employeeId,
            is_in_training_test: task.is_in_training_test,
          }));
        },
      );

      if (hotelSpecificTasks.length) {
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "hotel_specific_tasks",
          payload: hotelSpecificTasks.filter((task) => task.id),
        });

        const newHotelTaskResponse = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "hotel_specific_tasks",
            payload: hotelSpecificTasks.filter((task) => !task.id),
            returnFullResponse: true,
          },
        );

        if (newHotelTaskResponse.error) {
          throw new Error(newHotelTaskResponse.error.message);
        }
      }
    },
    async saveFormConfigurations() {
      try {
        this.submitting = true;
        await this.deleteRecords();
        await this.insertNewTasks();
        await this.updateHotelSpecificTasks();
        await this.refreshForm();
        this.snackbar.text = "Formular wurde erfolgreich gespeichert";
        this.snackbar.color = "success";
        this.snackbar.isOpen = true;
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Speichern des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      } finally {
        this.submitting = false;
      }
    },
  },
  async created() {
    const { formConfigurations, allTasksOptionsResponse } =
      await this.initializeForm();

    this.categoryAndTaskForm = formConfigurations || [];
    this.allTasks = allTasksOptionsResponse.data || [];
    this.employeeId = await this.$store.dispatch("getUserEmployeeId");
    this.isSenior = true;
  },
};
</script>

<style scoped>
.icon-checkbox-wrapper {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.v-switch :deep(label) {
  opacity: 100%;
}
</style>
