<template>
  <v-card class="ma-2">
    <v-tabs v-model="tab">
      <v-tab value="software">Software</v-tab>
      <v-tab value="generalTasks">Allgemeine Einarbeitungsaufgaben</v-tab>
      <v-tab value="locations">Standorte</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="software">
          <Software></Software>
        </v-window-item>
        <v-window-item value="generalTasks">
          <GeneralTrainingTasksForm></GeneralTrainingTasksForm>
        </v-window-item>
        <v-window-item value="locations">
          <Locations></Locations>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Software from "@/components/Settings/Hotels/Software.vue";
import GeneralTrainingTasksForm from "./Hotels/GeneralTrainingTasksForm.vue";
import Locations from "./Hotels/Locations.vue";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "software",
    };
  },
  methods: {},
  async mounted() {},
};
</script>
