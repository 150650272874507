import { format, subDays } from "date-fns";

export default function getFormattedShiftDate(timestamp) {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);
  const hours = date.getHours();

  const adjustedDate = hours <= 12 ? subDays(date, 1) : date;
  return format(adjustedDate, "dd.MM.yyyy");
}
