<template>
  <div>
    <v-row class="ma-2 pa-2">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  Schichtplan für Woche
                  {{
                    startDate.toLocaleDateString() +
                    " - " +
                    endDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                v-for="city in hotelCities"
                :key="city.id"
                class="ma-n2"
              >
                <v-card elevation="10">
                  <v-card-title>
                    {{ city.city }}
                  </v-card-title>
                  <v-card-text>
                    <v-btn
                      size="small"
                      rounded="lg"
                      :color="getButtonColor(hotel)"
                      v-for="hotel in city.hotels"
                      @click="
                        selectedHotel = hotel.id;
                        isSenior = true;
                        loadHotel(hotel.id);
                      "
                      :key="hotel.id"
                      :append-icon="hotel.progress == 100 ? 'mdi-check' : null"
                    >
                      {{ hotel.short_name }}
                      <span v-if="hotel.progress != 100"
                        >{{ hotel.progress }}%</span
                      >
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedHotel"
                  :items="hotels"
                  item-title="name"
                  item-value="id"
                  label="Hotel"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-btn
              :color="isSenior ? 'primary' : null"
              @click="
                isSenior = true;
                loadHotel();
              "
              v-if="hotelObject.bonus_points_senior"
              >Senior</v-btn
            >
            <v-btn
              :color="isSenior ? null : 'primary'"
              v-if="hotelObject.bonus_points_clerk"
              @click="
                isSenior = false;
                loadHotel();
              "
              >Clerk</v-btn
            >
            <v-data-table-virtual
              density="compact"
              :headers="shiftPlan.headers"
              :items="shiftPlan.shifts"
            >
              <template #item.employee="{ item }">
                <v-autocomplete
                  v-if="false"
                  clearable
                  :prepend-icon="
                    item.hotel_booking && !item.employee ? 'mdi-alert' : null
                  "
                  :disabled="!item.hotel_booking"
                  class="mt-2"
                  v-model="item.employee"
                  :items="employeeRecord"
                  item-title="employee_name"
                  item-value="employee_id"
                  variant="outlined"
                  density="compact"
                ></v-autocomplete>

                <div class="my-2">
                  <v-icon
                    class="mr-2"
                    @click="item.showSelection = !item.showSelection"
                    :icon="
                      item.showSelection ? 'mdi-arrow-up' : 'mdi-arrow-down'
                    "
                  ></v-icon>
                  <template
                    v-for="emp in item.employees"
                    :key="emp.employee_id"
                  >
                    <v-btn
                      size="x-small"
                      :append-icon="getIcon(item, emp)"
                      :disabled="checkAvailability(item, emp)"
                      v-if="
                        (item.showSelection ||
                          item.employeeId == emp.employee_id) &&
                        emp.status != 'Inaktiv'
                      "
                      :color="
                        item.employeeId == emp.employee_id ? 'primary' : null
                      "
                      @click="
                        item.employeeId = emp.employee_id;
                        saveShift(item, emp);
                      "
                      >{{ emp.employee_name
                      }}<v-rating
                        readonly
                        class="ml-1 mb-1"
                        density="compact"
                        size="x-small"
                        length="5"
                        v-model="emp.level"
                      ></v-rating
                    ></v-btn>
                  </template>
                  <v-btn
                    @click="saveShift(item)"
                    v-if="item.employeeId"
                    color="warning"
                    size="x-small"
                    >Entfernen</v-btn
                  >
                </div>
              </template>
              <template #item.hotel_booking="{ item }">
                <v-checkbox
                  label="Buchung"
                  v-model="item.hotel_booking"
                  @change="updateBooking(item)"
                ></v-checkbox>
              </template>
            </v-data-table-virtual>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Your template code goes here -->
  </div>
</template>

<script setup></script>
<script>
export default {
  name: "Shifts",
  data() {
    return {
      shifts: [],
      employeeRecord: [],
      hotelObject: {},
      hotelCities: [],
      hotels: [],
      selectedHotel: null,
      isSenior: true,
      today: new Date(),
      forwardDays: 7,
      backDays: 0,
      startDate: new Date(),
      endDate: new Date(),
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      shiftPlan: {
        month: null,
        year: null,
        days: null,
        headers: [
          {
            title: "Datum",
            align: "start",
            sortable: false,
            value: "date",
          },
          {
            title: "Reguläre Hotelbuchung",
            align: "center",
            sortable: false,
            value: "hotel_booking",
          },
          {
            title: "Mitarbeiter",
            align: "start",
            sortable: false,
            value: "employee",
          },
        ],
        shifts: [],
      },
      // Your data properties go here
    };
  },
  methods: {
    getIcon(item, emp) {
      if (emp.shifts.find((shift) => shift == item.date)) {
        return "mdi-domain";
      } else if (emp.holidays.find((holiday) => holiday == item.date)) {
        return "mdi-beach";
      } else if (emp.free_days.find((freeDay) => freeDay == item.date)) {
        return "mdi-home";
      } else {
        return null;
      }
    },
    checkAvailability(item, emp) {
      const alreadyOnShift = emp.shifts.find((shift) => shift == item.date);
      const onHoliday = emp.holidays.find((holiday) => holiday == item.date);
      const onFreeDay = emp.free_days.find((freeDay) => freeDay == item.date);
      if (
        (alreadyOnShift || onHoliday || onFreeDay) &&
        item.employee != emp.employee_name
      ) {
        return true;
      } else {
        return false;
      }
    },
    async saveShift(item, emp) {
      const shiftObject = {
        hotel_id: this.hotelObject.id,
        date: item.dateDB,
        is_training: false,
        is_senior: this.isSenior ? true : false,
        employee_id: emp ? emp.employee_id : null,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts_staging",
        payload: shiftObject,
      });
      this.loadHotel();
      this.getProgress();
    },
    getButtonColor(hotel) {
      if (hotel.id == this.selectedHotel) {
        return "primary";
      } else if (hotel.progress == 100) {
        return "success";
      } else {
        return null;
      }
    },
    async getProgress() {
      await this.getHotels();
      for (var i = 0; i < this.hotelCities.length; i++) {
        for (var j = 0; j < this.hotelCities[i].hotels.length; j++) {
          var hotel = this.hotelCities[i].hotels[j];
          var shiftDaysSenior = hotel.shift_days_senior
            ? hotel.shift_days_senior.length
            : 0;
          var shiftDaysClerk = hotel.shift_days_clerk
            ? hotel.shift_days_clerk.length
            : 0;
          var shiftDays = shiftDaysSenior + shiftDaysClerk;

          var bookedShifts = hotel.shifts_staging.filter(
            (shift) => shift.is_booked,
          ).length;

          if (bookedShifts > shiftDays) {
            shiftDays = bookedShifts;
          }

          var matched = 0;
          var toMatch = 0;
          if (shiftDays > 0) {
            var completeShifts = hotel.shifts_staging.filter(
              (shift) => shift.is_booked && shift.employee_id,
            );
            if (hotel.id == 14) {
              /*console.log(completeShifts.length + " = " + shiftDays);
              console.log(bookedShifts);
              console.log(completeShifts.length / shiftDays)
              */
            }
            var result = completeShifts.length / shiftDays;
            this.hotelCities[i].hotels[j].progress = Math.round(result * 100);
          } else {
            this.hotelCities[i].hotels[j].progress = 100;
          }
        }
      }
    },
    async updateBooking(shift) {
      let payload = {
        hotel_id: this.hotelObject.id,
        date: shift.dateDB,
        is_senior: this.isSenior,
        is_training: false,
        is_booked: shift.hotel_booking,
        check_id: 1,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts_staging",
        payload: payload,
      });
      this.getProgress();
      this.loadHotel();
    },
    async getHotels() {
      this.hotels = [];
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
        select:
          "*,hotels(name,short_name,id,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,shifts_staging(*)))",
        where: [
          { type: "is", key: "hotels.email", value: true },
          { type: "is", key: "hotel_city", value: true },
          {
            type: "gte",
            key: "hotels.shifts_staging.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "hotels.shifts_staging.date",
            value: this.endDate.toISOString(),
          },
        ],
      });
      response.data.forEach((city) => {
        city.hotels.sort((a, b) => a.name.localeCompare(b.name));
        city.hotels.forEach((hotel) => {
          this.hotels.push(hotel);
        });
      });
      this.hotelCities = response.data;
    },
    async loadHotel() {
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);

      this.shiftPlan.shifts = [];
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        select:
          "id,name,short_name,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,employees_hotels(*,employees!inner(name,first_name,last_name,status,shifts_staging(*),employee_date_events(*))),shifts_staging(*,employees(name,first_name,last_name,status))",
        where: [
          { type: "eq", key: "id", value: this.selectedHotel },
          {
            type: "is",
            key: "employees_hotels.is_senior",
            value: this.isSenior,
          },
          {
            type: "gte",
            key: "employees_hotels.employees.shifts_staging.date",
            value: start.toISOString(),
          },
          {
            type: "lte",
            key: "employees_hotels.employees.shifts_staging.date",
            value: end.toISOString(),
          },
          {
            type: "is",
            key: "shifts_staging.is_senior",
            value: this.isSenior,
          },
          {
            type: "gte",
            key: "employees_hotels.employees.employee_date_events.date",
            value: start.toISOString(),
          },
          {
            type: "neq",
            key: "employees_hotels.employees.status",
            value: "Inaktiv",
          },
          {
            type: "lte",
            key: "employees_hotels.employees.employee_date_events.date",
            value: end.toISOString(),
          },
        ],
      });
      this.hotelObject = response.data[0];
      console.log(this.hotelObject);
      this.shifts = this.hotelObject.shifts_staging;
      await this.createEmployeeShifts();
      this.createShiftPlan();
      this.hotelObject = response.data[0];
    },
    async changeDays(type) {
      this.isSenior = true;
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.shiftPlan.shifts = [];
      this.getProgress();
      if (this.hotelObject.id) {
        this.createShiftPlan();
      }
    },
    async createShiftPlan() {
      this.shiftPlan.shifts = [];

      let start = new Date(this.startDate);
      let end = new Date(this.endDate);
      var days = this.backDays + this.forwardDays;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "shifts_staging",
        select: "*,employees(name,status)",
        where: [
          { type: "gte", key: "date", value: start.toISOString() },
          { type: "lte", key: "date", value: end.toISOString() },
          { type: "eq", key: "hotel_id", value: this.hotelObject.id },
          { type: "neq", key: "employees.status", value: "Inaktiv" },
          { type: "is", key: "is_senior", value: this.isSenior },
        ],
      });
      var shifts = response.data;
      var shiftDateObject = {};
      shifts.forEach((shift) => {
        var date = new Date(shift.date);
        shiftDateObject[date.toISOString().slice(0, 10)] = shift;
      });
      for (let i = 0; i < days; i++) {
        var date = new Date(this.today);
        date.setDate(start.getDate() + i);
        var dateISO = date.toISOString().slice(0, 10);
        var weekday = this.weekdays[date.getDay()];

        if (this.isSenior) {
          var hotelBooking = this.hotelObject.shift_days_senior?.find(
            (day) => day === date.getDay(),
          );
        } else {
          var hotelBooking = this.hotelObject.shift_days_clerk?.find(
            (day) => day === date.getDay(),
          );
        }

        hotelBooking =
          hotelBooking >= 0 ||
          (shiftDateObject[dateISO] && shiftDateObject[dateISO].is_booked);
        this.shiftPlan.shifts.push({
          shiftInfo: shiftDateObject[dateISO],
          employeeId: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].employee_id
            : null,
          showSelection: !shiftDateObject[dateISO] && hotelBooking,
          date: date.toLocaleDateString() + ", " + weekday,
          dateDB: date.toISOString(),
          hotel_booking: hotelBooking,
          employee:
            shiftDateObject[dateISO] && shiftDateObject[dateISO].employees
              ? shiftDateObject[dateISO].employees.name
              : null,
          employees: this.employeeRecord,
        });
      }
    },
    // Your methods go here
    createEmployeeShifts() {
      var employeeCheck = {};
      this.shifts.forEach((shift) => {
        if (!employeeCheck[shift.employee_id]) {
          employeeCheck[shift.employee_id] = {
            employee_id: shift.employee_id,
            status: shift.employees ? shift.employees.status : null,
            employee_name: shift.employees ? shift.employees.name : null,
            count: 1,
          };
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count = 1;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 1;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 1;
          }
        } else {
          employeeCheck[shift.employee_id].count++;
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count++;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].clerk_count++;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].training_count++;
          }
        }
      });

      this.employeeRecord = Object.values(employeeCheck);
      this.employeeRecord.forEach((emp) => {
        if (this.isSenior) {
          var toCount = emp.senior_count;
        } else {
          var toCount = emp.clerk_count;
        }
        if (toCount > 40) {
          emp.level = 5;
        } else if (toCount > 30) {
          emp.level = 4;
        } else if (toCount > 20) {
          emp.level = 3;
        } else if (toCount > 10) {
          emp.level = 2;
        } else if (toCount > 0) {
          emp.level = 1;
        } else {
          emp.level = 0;
        }
        this.addExistingShifts(emp);
        this.addFreeDays(emp);
      });
      this.employeeRecord.sort((a, b) => b.count - a.count);
    },
    addExistingShifts(employee) {
      employee.shifts = [];
      var checkShifts = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkShifts && checkShifts.employees) {
        checkShifts = checkShifts.employees.shifts_staging;
      } else {
        checkShifts = [];
      }
      checkShifts.forEach((shift) => {
        var weekday = this.weekdays[new Date(shift.date).getDay()];
        shift.date = new Date(shift.date).toLocaleDateString() + ", " + weekday;
        employee.shifts.push(shift.date);
      });
    },
    addFreeDays(employee) {
      employee.free_days = [];
      employee.holidays = [];
      var checkFreeDays = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkFreeDays && checkFreeDays.employees) {
        checkFreeDays = checkFreeDays.employees.employee_date_events;
      } else {
        checkFreeDays = [];
      }
      checkFreeDays.forEach((freeDays) => {
        var weekday = this.weekdays[new Date(freeDays.date).getDay()];
        freeDays.date =
          new Date(freeDays.date).toLocaleDateString() + ", " + weekday;
        if (freeDays.is_holiday) {
          employee.holidays.push(freeDays.date);
        } else if (freeDays.is_free) {
          employee.free_days.push(freeDays.date);
        }
      });
    },
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      this.today.setDate(this.today.getDate() + daysUntilNextMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
    },
  },
  watch: {
    /*selectedHotel(newVal, oldVal) {
      if (newVal) {
        this.loadHotel();
      }
    },*/
  },

  mounted() {
    this.getNextMonday();
    this.getProgress();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
