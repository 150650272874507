<template>
  <v-form ref="form" v-model="valid" class="px-2">
    <v-card
      v-for="(category, index) in trainingForm"
      :key="category.selectedCategory?.id"
      class="my-3"
      elevation="5"
    >
      <v-card-text v-if="!category.delete">
        <!-- Category Row -->
        <v-row>
          <v-col cols="7" xs="6" md="4">
            <v-combobox
              v-model="category.selectedCategory"
              v-model:search="categoryInput"
              label="Kategorie"
              :items="categories"
              item-title="name"
              item-value="id"
              :hide-no-data="!categoryInput"
              :readonly="!!category.selectedCategory?.id"
              return-object
              variant="solo-filled"
              :rules="[(v) => !!v?.name.trim() || 'Kategorie ist erforderlich']"
              @update:modelValue="
                (value) => onCategoryModelUpdate(value, index)
              "
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    <kbd>↵ Enter</kbd> drücken, um
                    <strong>{{ categoryInput }}</strong> hinzuzufügen
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-spacer />
          <v-col cols="5" xs="4" md="3" align="end">
            <v-btn
              v-if="index != 0"
              icon
              @click="changeCategoryOrder(index, 'up')"
              size="small"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn
              v-if="index != trainingForm.length - 1"
              icon
              @click="changeCategoryOrder(index, 'down')"
              class="ml-2"
              size="small"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn @click="deleteCategory(index)" class="ml-2">
              <v-icon>mdi-delete</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Gesamte Kategorie Löschen
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Tasks -->
        <div v-for="(task, taskIndex) in category.training_general_tasks">
          <v-row
            align="center"
            :key="task.selectedHotelTask?.id"
            v-if="!task.delete"
            class="my-n4"
          >
            <!-- Task Name -->
            <v-col cols="8" md="4" class="px-0 mr-0 d-flex align-top">
              <v-btn
                icon
                size="small"
                flat
                class="mr-1"
                @click="deleteTask(index, taskIndex)"
              >
                <v-icon>mdi-close</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Aufgabe Löschen
                </v-tooltip>
              </v-btn>
              <v-combobox
                v-model="task.selectedHotelTask"
                v-model:search="taskInput"
                label="Aufgabe"
                class="pr-3"
                density="compact"
                :items="allTasks"
                item-title="name"
                item-value="id"
                :hide-no-data="!taskInput"
                :readonly="!!task.selectedHotelTask?.id"
                return-object
                hide-selected
                variant="outlined"
                :rules="[(v) => !!v?.name.trim() || 'Aufgabe ist erforderlich']"
                @update:modelValue="
                  (value) =>
                    onTaskModelUpdate({
                      value,
                      categoryIndex: index,
                      taskIndex,
                    })
                "
                @keydown.enter="
                  onEnterTask({
                    categoryIndex: index,
                    taskIndex,
                  })
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      <kbd>↵ Enter</kbd> drücken, um
                      <strong>{{ taskInput }}</strong> hinzuzufügen
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

            <!-- Icon Checkboxes  -->
            <v-col cols="1" md="1" class="mx-n4">
              <div class="icon-checkbox-wrapper">
                <v-icon>mdi-alert</v-icon>
                <v-checkbox class="mt-n5" v-model="task.is_crucial">
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="300"
                    >Sicherheitsrelevante Frage: <br />
                    Bei nicht korrekter Ausführung ist eine weitere Einarbeitung
                    zwingend notwendig.</v-tooltip
                  ></v-checkbox
                >
              </div>
            </v-col>
            <v-col cols="1" md="1">
              <div class="icon-checkbox-wrapper">
                ZW
                <v-checkbox
                  class="mt-n5"
                  v-model="task.alsoInIntermediateCheck"
                >
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="300"
                    >Auch in Zwischenstand</v-tooltip
                  ></v-checkbox
                >
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="2" xs="2" md="2" align="end">
              <v-btn
                v-if="taskIndex !== 0"
                icon
                @click="changeTaskOrder(index, taskIndex, 'up')"
                size="x-small"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                v-if="taskIndex !== category.training_general_tasks.length - 1"
                icon
                @click="changeTaskOrder(index, taskIndex, 'down')"
                size="x-small"
                class="ml-2"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-divider
              class="horizontal-divider mb-6 hidden-md-and-up"
              :thickness="2"
            ></v-divider>
          </v-row>
        </div>

        <!-- Add Task Button -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn size="x-small" icon @click="addTask(index)" class="ml-n2">
              <v-icon>mdi-plus</v-icon>
              <v-tooltip activator="parent" location="bottom" open-delay="300"
                >Neue Aufgabe hinzufügen</v-tooltip
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row no-gutters>
      <v-col cols="12" md="5" class="mb-2">
        <v-btn @click="addCategory(index)" color="primary"
          >Neue Kategorie hinzufügen</v-btn
        >
      </v-col>
      <v-spacer />
      <v-col cols="12" md="3" :align="mdAndUp ? 'end' : 'start'">
        <v-btn
          v-if="trainingForm.length > 0"
          @click="submitForm"
          :color="!valid ? 'grey' : 'success'"
          :loading="submitting"
          :disabled="submitting"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          <v-tooltip activator="parent" location="top" v-if="!valid">
            Ein oder mehrere Felder sind leer
          </v-tooltip>
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
  <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script setup>
import { useDisplay } from "vuetify";

const { mdAndUp } = useDisplay();
</script>

<script>
export default {
  data() {
    return {
      isClientSide: false,
      submitting: false,
      form: null,
      valid: true,
      categories: [],
      trainingForm: [],
      allTasks: [],
      employeeId: null,
      categoryInput: "",
      taskInput: "",
      snackbar: {
        isOpen: false,
        text: "",
        color: null,
      },
    };
  },
  methods: {
    async initializeForm() {
      let formConfigurations = null;
      let allCategoriesResponse = null;
      let allTasksOptionsResponse = null;

      try {
        const formStructure = await this.$store.dispatch("getSupabaseEntity", {
          select: "*,training_general_tasks!inner(*, hotel_tasks(*))",
          table: "hotel_task_categories",
          order: {
            key: "order",
            ascending: true,
          },
          where: [
            {
              type: "is",
              key: "training_general_tasks.deleted_at",
              value: null,
            },
          ],
        });

        formConfigurations = formStructure.data?.map((category, index) => {
          return {
            selectedCategory: {
              id: category.id,
              name: category.name,
            },
            order: category.order,
            delete: false,
            training_general_tasks: category.training_general_tasks
              .map((task) => {
                return {
                  id: task.id,
                  selectedHotelTask: {
                    id: task.task_id,
                    name: task.hotel_tasks.name,
                  },
                  order: task.order,
                  alsoInIntermediateCheck: !task.is_only_final,
                  created_by_id: task.created_by_id,
                  delete: false,
                  is_crucial: task.is_crucial,
                };
              })
              .sort((a, b) => a.order - b.order),
          };
        });

        allCategoriesResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "hotel_task_categories",
            where: [
              {
                type: "neq",
                key: "id",
                value: "13", // exclude "Leistungsnachweis" category
              },
            ],
          },
        );

        allTasksOptionsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            table: "hotel_tasks",
          },
        );
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Laden des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      }

      return {
        formConfigurations,
        allCategoriesResponse,
        allTasksOptionsResponse,
      };
    },
    addCategory(index) {
      this.trainingForm.push({
        id: null,
        is_general: true,
        delete: false,
        order: index + 1,
        selectedCategory: {
          id: null,
          name: "",
        },
        training_general_tasks: [],
      });
    },
    deleteCategory(index) {
      if (this.trainingForm[index]) {
        this.trainingForm[index].delete = true;
      }
    },
    onCategoryModelUpdate(value, index) {
      if (typeof value === "string") {
        // User entered a new category
        this.trainingForm[index].selectedCategory = {
          id: null,
          name: value,
        };
      } else if (value && value.id) {
        // User selected an existing category
        this.trainingForm[index].selectedCategory = value;
      } else {
        // User cleared the input
        this.trainingForm[index].selectedCategory = {
          id: null,
          name: "",
        };
      }
    },
    changeCategoryOrder(index, direction) {
      if (direction === "up" && index > 0) {
        const temp = this.trainingForm[index];
        this.trainingForm.splice(index, 1);
        this.trainingForm.splice(index - 1, 0, temp);
      } else if (direction === "down" && index < this.trainingForm.length - 1) {
        const temp = this.trainingForm[index];
        this.trainingForm.splice(index, 1);
        this.trainingForm.splice(index + 1, 0, temp);
      }
    },
    addTask(categoryIndex) {
      const currentCategory = this.trainingForm[categoryIndex];

      this.trainingForm[categoryIndex].training_general_tasks.push({
        id: null,
        selectedHotelTask: {
          id: null,
          name: "",
        },
        order:
          this.trainingForm[categoryIndex]?.training_general_tasks?.length + 1,
        is_crucial: false,
        alsoInIntermediateCheck: true,
        delete: false,
        created_by_id: this.employeeId,
      });
    },
    deleteTask(categoryIndex, taskIndex) {
      if (
        this.trainingForm[categoryIndex] &&
        this.trainingForm[categoryIndex].training_general_tasks[taskIndex]
      ) {
        this.trainingForm[categoryIndex].training_general_tasks[
          taskIndex
        ].delete = true;
      }
    },
    onTaskModelUpdate({ value, categoryIndex, taskIndex }) {
      if (typeof value === "string") {
        this.trainingForm[categoryIndex].training_general_tasks[
          taskIndex
        ].selectedHotelTask = {
          id: null,
          name: value,
        };
      } else if (value && value.id) {
        // User selected an existing task
        this.trainingForm[categoryIndex].training_general_tasks[
          taskIndex
        ].selectedHotelTask = value;
      } else {
        // User cleared the input
        this.trainingForm[categoryIndex].training_general_tasks[
          taskIndex
        ].selectedHotelTask = {
          id: null,
          name: "",
        };
      }
    },
    onEnterTask({ categoryIndex, taskIndex }) {
      if (!this.taskInput) return;
      this.onTaskModelUpdate({
        value: this.taskInput,
        categoryIndex,
        taskIndex,
      });
      this.taskInput = "";
    },
    changeTaskOrder(categoryIndex, taskIndex, direction) {
      const tasks = this.trainingForm[categoryIndex].training_general_tasks;
      if (direction === "up" && taskIndex > 0) {
        const temp = tasks[taskIndex];
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex - 1, 0, temp);
      } else if (direction === "down" && taskIndex < tasks.length - 1) {
        const temp = tasks[taskIndex];
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex + 1, 0, temp);
      }
    },
    async refreshForm() {
      const {
        formConfigurations,
        allCategoriesResponse,
        allTasksOptionsResponse,
      } = await this.initializeForm();

      this.trainingForm = formConfigurations;
      this.categories = allCategoriesResponse?.data || [];
      this.allTasks = allTasksOptionsResponse.data || [];
    },
    submitForm() {
      if (this.form) {
        this.form.validate();
      }
      if (this.valid) {
        this.saveFormConfigurations();
      }
    },
    async deleteRecords() {
      for (const category of this.trainingForm) {
        if (category.delete && category.selectedCategory?.id) {
          await this.$store.dispatch("updateSupabaseEntity", {
            table: "hotel_task_categories",
            payload: { order: null },
            id: category.selectedCategory.id,
          });
          for (const task of category.training_general_tasks) {
            if (task.id) {
              await this.$store.dispatch("updateSupabaseEntity", {
                table: "training_general_tasks",
                payload: { deleted_at: new Date().toISOString(), order: null },
                id: task.id,
              });
            }
          }
        }
      }

      for (const category of this.trainingForm) {
        if (!category.training_general_tasks && category.delete) continue;

        category.training_general_tasks
          .filter((task) => task.delete && task.id)
          .forEach(async (task) => {
            await this.$store.dispatch("updateSupabaseEntity", {
              table: "training_general_tasks",
              payload: { deleted_at: new Date().toISOString() },
              id: task.id,
            });
          });
      }

      this.trainingForm = this.trainingForm
        .filter((category) => !category.delete)
        .map((category) => ({
          ...category,
          training_general_tasks: category.training_general_tasks.filter(
            (task) => !task.delete,
          ),
        }));
    },
    async insertNewCategoriesAndTasks() {
      const newCategories = this.trainingForm
        .filter((category) => !category.selectedCategory?.id)
        .map((category, index) => ({
          name: category.selectedCategory.name.trim(),
          is_general: true,
          order: index + 1,
        }));

      if (newCategories.length) {
        const { data: insertedCategories, error } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "hotel_task_categories",
            payload: newCategories,
            returnFullResponse: true,
          },
        );

        if (error) {
          throw new Error(error.message);
        }

        insertedCategories.forEach((insertedCategory) => {
          this.trainingForm.find(
            (category) =>
              category.selectedCategory.name === insertedCategory.name,
          ).selectedCategory.id = insertedCategory.id;
        });
      }

      const newTasks = this.trainingForm.flatMap((category) => {
        return category.training_general_tasks
          .filter((task) => !task.selectedHotelTask.id)
          .map((task) => ({
            name: task.selectedHotelTask.name.trim(),
            category_id: category.selectedCategory.id,
          }));
      });

      if (newTasks.length) {
        const { data: insertedTasks } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "hotel_tasks",
            payload: newTasks,
            returnFullResponse: true,
          },
        );

        insertedTasks.forEach((insertedTask) => {
          this.trainingForm
            .find(
              (category) =>
                category.selectedCategory.id === insertedTask.category_id,
            )
            .training_general_tasks.find(
              (task) => task.selectedHotelTask.name === insertedTask.name,
            ).selectedHotelTask.id = insertedTask.id;
        });
      }
    },
    async updateCategoryOrder() {
      const updatedCategories = this.trainingForm.map((category, index) => ({
        id: category.selectedCategory.id,
        order: index + 1,
      }));

      if (updatedCategories.length) {
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "hotel_task_categories",
          payload: updatedCategories,
        });
      }
    },
    async updateTrainingGeneralTasks() {
      const trainingGeneralTasks = this.trainingForm.flatMap((category) => {
        return category.training_general_tasks.map((task, index) => {
          return {
            ...(task.id && { id: task.id }),
            category_id: category.selectedCategory.id,
            task_id: task.selectedHotelTask.id,
            is_only_final: !task.alsoInIntermediateCheck,
            is_crucial: task.is_crucial,
            order: index + 1,
            created_by_id: task.created_by_id ?? this.employeeId,
            modified_at: new Date().toISOString(),
          };
        });
      });

      let newHotelTaskResponse;

      if (trainingGeneralTasks.length) {
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "training_general_tasks",
          payload: trainingGeneralTasks.filter((task) => task.id),
        });

        newHotelTaskResponse = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "training_general_tasks",
            payload: trainingGeneralTasks.filter((task) => !task.id),
            returnFullResponse: true,
          },
        );

        if (newHotelTaskResponse.error) {
          throw new Error(newHotelTaskResponse.error.message);
        }
      }
    },
    async saveFormConfigurations() {
      try {
        this.submitting = true;
        await this.deleteRecords();
        await this.insertNewCategoriesAndTasks();
        await this.updateCategoryOrder();
        await this.updateTrainingGeneralTasks();
        await this.refreshForm();
        this.snackbar.text = "Formular wurde erfolgreich gespeichert";
        this.snackbar.color = "success";
        this.snackbar.isOpen = true;
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Speichern des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      } finally {
        this.submitting = false;
      }
    },
  },
  async created() {
    this.isClientSide = true;
    const {
      formConfigurations,
      allCategoriesResponse,
      allTasksOptionsResponse,
    } = await this.initializeForm();

    this.categories = allCategoriesResponse?.data || [];
    this.trainingForm = formConfigurations || [];
    this.allTasks = allTasksOptionsResponse.data || [];
    this.employeeId = await this.$store.dispatch("getUserEmployeeId");
  },
};
</script>

<style scoped>
.icon-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
