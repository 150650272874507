<template>
  <v-card class="d-flex flex-column" style="height: 100vh">
    <v-card-title
      class="bg-blue-grey-darken-3 d-flex align-center px-3 fixed-top"
    >
      <v-img src="../../assets/bellcaptain_glocke.png" height="56px"></v-img>
      <span :style="`font-size: ${smAndUp ? '20px' : '15px'};`">
        Zwischenabnahme
      </span>
      <v-spacer></v-spacer>
      <v-icon @click="closeDialog()" class="cursor-pointer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-stepper alt-labels height="100%" class="view-content">
        <template v-slot:default="{ prev, next }">
          <v-stepper-header class="fixed-stepper">
            <v-stepper-item title="Hinweise" value="1" editable>
            </v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Aufgaben"
              value="2"
              :editable="isTestStarted"
            ></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Feedback"
              value="3"
              :editable="isTestStarted"
            ></v-stepper-item>
          </v-stepper-header>
          <v-stepper-window>
            <v-stepper-window-item value="1">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-card elevation="3" class="ma-1">
                      <v-card-text>
                        <v-row align="center" no-gutters>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">{{ city }}</v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-domain</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ hotel.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-calendar</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ examinationDate }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-account</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ trainee?.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center"
                            >Level:</v-col
                          >
                          <v-col cols="9" xs="9" md="11">
                            {{
                              trainingHotelResults.is_senior
                                ? "Senior"
                                : "Clerk"
                            }}
                          </v-col>
                          <v-col
                            cols="12"
                            class="mt-2 d-flex justify-end align-center"
                          >
                            Abnahme durch
                            {{ examiner?.name ?? "" }}
                            <v-skeleton-loader
                              v-if="!examiner?.name"
                              width="100px"
                              type="text"
                            >
                            </v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-row class="mt-2 px-1">
                      <v-col cols="12"
                        ><h4>
                          Beurteilung über den Zwischenstand der Einarbeitung
                        </h4></v-col
                      >
                      <v-col cols="12">
                        <p>
                          In dieser Beurteilung werden gewisse Punkte geprüft,
                          um den Wissensstand des Trainees besser beurteilen zu
                          können.
                        </p>
                        <br />
                        <p>
                          Im Folgenden werden aus den Bereichen
                          Notfalleinrichtungen, Hotelübersicht, Hotelumgebung,
                          Bellcaptain sowie zum Hotelsystem Fragen gestellt.
                          Außerdem werden die zu erbringenden hotelspezifischen
                          Dienstleistungen überprüft, inwiefern diese schon
                          alleine und eigenständig oder noch mit Hilfe
                          bearbeitet werden können. Zudem gibt es Fragen über
                          sicherheitsrelevante Aufgaben / Informationen und
                          grundlegende Systemfragen, um zu überprüfen, wo der
                          größte Schulungsbedarf liegt.
                        </p>
                        <br />
                        <p>
                          Es muss lediglich abgehakt werden, ob der Trainee die
                          Aufgaben und Fragen ohne Hilfestellung, mit
                          Hilfestellung der Dienstanweisung oder gar nicht
                          beantworten konnte. Der Trainer darf hier nicht mit
                          eingreifen, da sich sonst das Ergebnis vom
                          Zwischenstand verfälscht. In der Spalte Bemerkung soll
                          der Prüfer seine Einschätzung über den Wissensstand
                          des Trainees hinterlegen, sowie die Anzahl der noch
                          benötigten Einarbeitungszeit.
                        </p>
                        <br />
                        <p>
                          Diese Beurteilung dient dazu, ein besseres Fazit aus
                          der Einarbeitung zu gewinnen und den
                          Einarbeitungsprozess zu optimieren.
                        </p>
                        <br />
                      </v-col>
                      <v-col align="center">
                        <v-btn color="green" class="mt-3" @click="next"
                          >Weiter</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="2">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <IntermediaryTaskEvaluationForm
                      :taskExaminationForm="taskExaminationForm"
                      :isCompleted="isTestComplete"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="3">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-row>
                      <v-col cols="12" align-self="center">
                        <v-checkbox
                          v-model="feedbackComment.visible_to_others"
                          label="Bemerkung freigeben"
                          hide-details
                          density="compact"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-expansion-panels
                          static
                          :model-value="commentFieldPanel"
                        >
                          <v-expansion-panel title="Bemerkung">
                            <v-expansion-panel-text>
                              <v-container fluid>
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <v-textarea
                                      :readonly="isTestComplete"
                                      v-model="feedbackComment.comment"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col
                        v-if="isResultReviewed"
                        cols="12"
                        class="d-flex justify-center"
                      >
                        <v-icon color="green" class="mr-1">
                          mdi-check-circle
                        </v-icon>
                        Ergebnis genehmigt am
                        {{
                          new Date(
                            trainingHotelResults?.reviewed_intermediary_at,
                          ).toLocaleDateString("de")
                        }}
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          @click="reviewResult"
                          color="success"
                          :loading="isSubmitting"
                          :disabled="isSubmitting"
                        >
                          {{ isResultReviewed ? "Speichern" : "Genehmigen" }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>
          </v-stepper-window>
        </template>
      </v-stepper>
    </v-card-text>
  </v-card>
  <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script setup>
import IntermediaryTaskEvaluationForm from "./IntermediaryTaskEvaluationForm.vue";
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useDisplay } from "vuetify";
import { supabase } from "@/supabase";

const store = useStore();
const { smAndUp } = useDisplay();

const props = defineProps({
  trainee: Object,
  hotel: Object,
  city: String,
  trainingResult: Object,
  closeDialog: Function,
});

const examinationDate = ref(new Date().toLocaleDateString("de"));

const examiner = ref(null);
const trainee = ref(props.trainee);
const hotel = ref(props.hotel);
const city = ref(props.city);
const closeDialog = props.closeDialog;
const trainingHotelResults = ref(props.trainingResult);

const currentUser = ref(null);
const taskExaminationForm = ref([]);
const commentFieldPanel = ref([0]);
const showComment = ref(false);
const feedbackComment = ref({});

const isTestStarted = computed(() =>
  Boolean(trainingHotelResults?.value?.started_intermediary_at),
);

const isTestComplete = computed(() =>
  Boolean(trainingHotelResults?.value?.completed_intermediary_at),
);

const isResultReviewed = computed(() =>
  Boolean(trainingHotelResults?.value?.reviewed_intermediary_at),
);

const reviewResult = async () => {
  try {
    snackbar.text = !isResultReviewed.value
      ? "Ergebnis genehmigt."
      : "Änderungen gespeichert.";

    isSubmitting.value = true;
    if (!isResultReviewed.value) {
      const { data, error } = await supabase
        .from("training_hotel_results")
        .update({
          reviewed_intermediary_at: new Date(),
        })
        .eq("id", trainingHotelResults.value.id)
        .select();

      if (error) {
        throw error;
      }

      trainingHotelResults.value = data[0];
    }

    if (feedbackComment.value.id) {
      await reviewFeedbackComment();
    }

    snackbar.color = "success";
    snackbar.isOpen = true;
  } catch (error) {
    console.error("Error reviewing result: ", error);
    snackbar.text = "Fehler beim Speichern des Ergebnisses.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  } finally {
    isSubmitting.value = false;
  }
};

const isSubmitting = ref(false);

const snackbar = reactive({
  isOpen: false,
  text: "",
  color: "",
});

const initializeTaskExaminationForm = (formStructure) => {
  const filteredFormStructure = formStructure.filter(
    (category) =>
      (category.is_general && category.training_general_tasks.length > 0) ||
      (!category.is_general && category.hotel_specific_tasks.length > 0),
  );

  filteredFormStructure
    .map((category) => {
      category.training_general_tasks.sort((a, b) => a.order - b.order);
      category.hotel_specific_tasks.sort((a, b) => a.order - b.order);
    })
    .sort((a, b) => a.order - b.order);

  taskExaminationForm.value = filteredFormStructure;
};

/** ------- Handle Comments ------- */

/**
 * get comment when training is complete and if author or visible to others
 */
const fetchFeedbackComment = async () => {
  if (trainingHotelResults.value.completed_intermediary_at) {
    const { data: feedbackData, error: feedbackError } = await supabase
      .from("training_feedback_comments")
      .select("*")
      .eq("training_hotel_results_id", trainingHotelResults.value.id)
      .eq("is_intermediary_feedback", true);

    if (feedbackError) {
      throw feedbackError;
    }

    feedbackComment.value = feedbackData[0] ?? {};
  }
};

const reviewFeedbackComment = async () => {
  const { error } = await supabase
    .from("training_feedback_comments")
    .update(feedbackComment.value)
    .eq("id", feedbackComment.value.id);

  if (error) {
    throw error;
  }
};

onMounted(async () => {
  try {
    const hotelId = hotel.value.id;
    const isSeniorShift = trainingHotelResults.value.is_senior;
    currentUser.value = store.state.userInfo;

    // get current User
    currentUser.value = store.state.userInfo;

    showComment.value = trainingHotelResults.value.show_intermediary_comment;

    if (trainingHotelResults.value.completed_intermediary_at) {
      examinationDate.value = new Date(
        trainingHotelResults.value.completed_intermediary_at,
      ).toLocaleDateString("de");
    }

    // get tasks and results
    let initialFetchingQuery = supabase
      .from("hotel_task_categories")
      .select(
        `*, hotel_specific_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", result:training_hotel_task_results(*)"
            : ""
        }), training_general_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", result:training_hotel_task_results(*)"
            : ""
        })`,
      )
      .neq("id", 13) // exclude "Leistungsnachweis" category
      .is("hotel_specific_tasks.is_in_training_test", true)
      .eq("hotel_specific_tasks.hotel_id", hotelId)
      .is("hotel_specific_tasks.is_senior", isSeniorShift)
      .is("hotel_specific_tasks.deleted_at", null)
      .is("hotel_specific_tasks.is_only_final", false)
      .is("training_general_tasks.is_only_final", false)
      .is("training_general_tasks.deleted_at", null);

    if (trainingHotelResults.value.id) {
      initialFetchingQuery
        .eq(
          "hotel_specific_tasks.training_hotel_task_results.training_hotel_result_id",
          trainingHotelResults.value.id,
        )
        .eq(
          "training_general_tasks.training_hotel_task_results.training_hotel_result_id",
          trainingHotelResults.value.id,
        );
    }
    const { data: formStructure, error: fetchError } =
      await initialFetchingQuery;

    if (fetchError) {
      throw error;
    }

    if (
      trainingHotelResults.value.intermediary_examiner_id !==
      currentUser.value.id
    ) {
      const { data: examinerData, error: examinerError } = await supabase
        .from("employees")
        .select("id, name")
        .eq("id", trainingHotelResults.value.intermediary_examiner_id);

      if (examinerError) {
        throw examinerError;
      }

      examiner.value = examinerData[0];
    } else {
      examiner.value = currentUser.value;
    }

    await fetchFeedbackComment();
    initializeTaskExaminationForm(formStructure);
  } catch (error) {
    console.error("Error fetching data: ", error);
    snackbar.text = "Fehler beim Laden der Daten. Bitte Formular neu laden.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
});
</script>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.fixed-stepper {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: white;
}

.view-content {
  overflow-y: auto;
  margin-top: 170px;
  max-height: calc(100% - 170px);
}
</style>
